export const ROLE_GENERAL_USER = 'ROLE_GENERAL_USER';
export const ROLE_CONSULTANT = 'ROLE_CONSULTANT';
export const ROLE_SYS_ADMIN = 'ROLE_SYS_ADMIN';
export const ROLE_GROUP_MEMBER = 'ROLE_GROUP_MEMBER';
export const ROLE_GROUP_ADMIN = 'ROLE_GROUP_ADMIN';

export const FREE_PLAN_NAME = 'Free Plan';
export const BASIC_PLAN_NAME = 'Basic Plan';
export const FREE_PLAN_OWNED_PROJ_LIMIT = 1;
export const FREE_PLAN_SHARED_PROJ_LIMIT = 1;

export const ONBOARDING_RESET_DEC24 = 'reset_dec24';
export const ONBOARDING_WELCOME_DIALOG = 'welcome_dialog';
export const ONBOARDING_DASHBOARD_DIALOG = 'dashboard_dialog';
export const ONBOARDING_PROJ_PLAN_DIALOG = 'project_plan_dialog';
export const ONBOARDING_WBS_DIALOG = 'wbs_dialog';
export const ONBOARDING_WORK_SCHEDULE_DIALOG = 'work_schedule_dialog';
export const ONBOARDING_WBS_ZOOMED_DIALOG = 'wbs_zoomed_dialog';
export const ONBOARDING_NEW_PROJECT = 'wbs_new_project';
export const ONBOARDING_SPRINTVIEW_DIALOG = 'sprintview_dialog';
export const ONBOARDING_DELIVERABLE_DIALOG = 'deliverable_dialog';
export const ONBOARDING_WORKPACKAGE_DIALOG = 'workpackage_dialog';
export const ONBOARDING_TASK_DIALOG = 'task_dialog';
export const ONBOARDING_EXAMPLE_PROJECT_DIALOG = 'example project dialog';

export const VIDEO_URL_STARTUP = 'https://youtu.be/2w3F4BnEZQY';
export const VIDEO_URL_SPRINTVIEW = 'https://youtu.be/bBObL41ayb0';
export const VIDEO_URL_PLANS = 'https://youtu.be/xB6W4xj220A';
export const VIDEO_URL_PROJECT = 'https://youtu.be/LabYXbHM1js';
export const VIDEO_URL_DASHBOARD = 'https://youtu.be/TMi0UGef8BQ';
export const VIDEO_URL_DELIVERABLE = 'https://youtu.be/WtAY2oTsIyk';
export const VIDEO_URL_TASKGROUP = 'https://youtu.be/U7iQEHD1e9o';
export const VIDEO_URL_TASK = 'https://youtu.be/GdAShKk9_us';
