/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import FlatList from 'flatlist-react';
import { FixedSizeList } from 'react-window';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake'; // see https://pdfmake.github.io/
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { saveCurrentProject, saveWorkflows } from '../../data/actions/userInterface';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import { red, yellow, grey, white } from '@material-ui/core/colors';
import { Button, ButtonGroup, Select, MenuItem } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Person from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import EventIcon from '@material-ui/icons/Event';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import NameIcon from '@material-ui/icons/SupervisorAccount';
import { DragDropContext } from 'react-beautiful-dnd';
import StatusButton from '../styled_components/StatusButton';
import { rollUpDates } from '../../data/actions/userInterface';
import { ContentWrapper } from '../styled_components/ContentWrapper';
import { PriorityIcon } from '../Icons/PriorityIcon';
import { DateRange } from '@material-ui/icons';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Typography from '@material-ui/core/Typography';
import * as STATUS from '../../constants/status';
import { useTheme } from '@material-ui/styles';
import {
  SupervisorAccount,
  Lock,
  Event,
  AccountTree,
  Assignment,
  DoubleArrow,
  Add,
} from '@material-ui/icons';
import { Heading } from '../styled_components/Heading';
import { Row } from '../styled_components/Row';
import { TeamModal } from './TeamModal';
import { InputField } from '../styled_components/InputField';
import * as utils from '../../utils/generalUtilities.js';
import {
  dispatchUpdateViewState,
  handleMenu,
  updateViewState,
  setUserColleguePref,
} from '../../data/actions/userInterface';
import { NamePickerDialog } from '../NamePickerDialog';
import * as NAV from '../../constants/navigation';
import * as USER from '../../constants/user';
import * as TASKS from '../../constants/tasks';
import { Fragment } from 'react';
import { dispatchUpdateUsibilityReport } from '../../data/actions/app';
import { date } from 'yup';
const assignedToAvatarSize = 25;

const projectSummaryStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'block',
    height: window.innerHeight,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    minWidth: '100%',
    marginTop: '-55px',
  },
  parentContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 20,
    // width: 1200,
    marginTop: '5px',
    height: 'calc(100vh - 230px)',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#888',
    backgroundColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginLeft: 20,
    marginRight: 20,
  },
  statusButtonRow: {
    flex: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 20,
  },
  absButton: {
    position: 'absolute', // note parent is position: 'relative' to make this abs rel to parent
    right: 15,
    top: 25,
    width: 60,
    height: 30,
    // border: '1px solid #'
  },
  editor: {
    // position: 'absolute', // note parent is position: 'relative' to make this abs rel to parent
    width: 210,
    height: 50,
    backgroundColor: 'white',
    // zIndex: 99,
    // border: '1px solid #'
  },
  editorBox: {
    position: 'absolute', // note parent is position: 'relative' to make this abs rel to parent
    padding: 10,
    backgroundColor: '#ffffffe0',
    borderRadius: 7,
    zIndex: 99,
  },
  urProjectRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // height: 40,
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '2px',
    marginBottom: '2px',
  },
  dueIcon: {
    fontSize: '1.2rem',
    marginLeft: '0px',
    width: '40px',
  },
  dueText: {
    fontSize: '.9rem',
    marginLeft: '0px',
    marginBottom: '1px',
    width: '140px',
    color: '#555',
  },
  blankDueText: {
    fontSize: '.9rem',
    marginLeft: '0px',
    marginBottom: '1px',
    width: '180px',
    color: '#555',
  },
  alertComponentText: {
    flex: 1,
    fontSize: '1.1rem',
    marginLeft: '0px',
    marginTop: '-2px',
    marginBottom: '5px',
    // fontWeight: 600,
    color: '#555',
    cursor: 'pointer',
    '&:hover': {
      // background: theme.palette.secondary.main,
      color: 'blue',
      textDecoration: 'underline',
    },
  },
  reportTitle: {
    alignSelf: 'center',
    fontSize: '1.4rem',
    marginLeft: '0px',
    marginTop: '5px',
    marginBottom: '10px',
    fontWeight: 600,
    color: '#666',
  },
  buttonTitle: {
    alignSelf: 'flex-start',
    fontSize: '1rem',
    marginRight: '1px',
    fontWeight: 600,
    color: '#666',
  },
  reportSubTitle: {
    alignSelf: 'center',
    fontSize: '1rem',
    marginLeft: '0px',
    marginTop: '5px',
    marginBottom: '10px',
    fontWeight: 600,
    color: '#6164C9',
  },
  reportlist: {
    marginLeft: '0px',
    overflowY: 'scroll',
    height: 'calc(100vh - 250px)',
  },
  clipButton: {
    marginTop: '-7px',
  },
  pdfButton: {
    marginTop: '-7px',
    color: '#6164C9',
    padding: '0px',
    // backgroundColor: 'yellow'
  },
  attentionButton: {
    minWidth: 70,
  },
  attentionButtonGroup: {
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  priorityButtonGroup: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: 10,
    marginRight: 20,
  },
  statusButtonGroup: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: 20,
    marginRight: 10,
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },

  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
  },
  assignedToAvatarText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  statusFilterButton: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    alignSelf: 'center',
    padding: '1px 8px 1px 8px',
    // minWidth: '100px',
    minWidth: '100px',
    // maxWidth: '85px',
    // width: '400px',
    height: '42px',
    border: '1px solid #CCC',
    borderRadius: '12px',
  },
  statusFilterButtonText: {
    flex: 0,
    color: 'white',
    fontSize: 15,
    fontWeight: 700,
    alignText: 'center',
  },
  statusFilterCountText: {
    flex: 0,
    color: 'white',
    fontSize: 14,
    fontWeight: 700,
    alignText: 'center',
  },
  attentionMessage: {
    // margin: theme.spacing(2),
    color: '#888',
  },
  loadingIndicator: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 200,
    // backgroundColor: 'yellow'
  },
  statusButton: {
    color: ({ buttonColor }) =>
      // theme.palette.getContrastText(buttonColor),
      '#222',
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    '&:hover': {
      backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    },
    width: '120px',
  },
  taskDate: {
    // maxHeight: '.9em',
    marginTop: -6,
    marginRight: 10,
    // backgroundColor: 'yellow'
  },
  dateString: {
    fontSize: '.9em',
  },
  input: {
    width: '240px',
    marginRight: '10px',
    marginBottom: '15px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: '20px',
    width: '150px',
    '& .MuiInputBase-input': {
      // borderRadius: 4,
      position: 'relative',
      // border: "1px solid red",
      fontSize: 16,
      padding: '7px 10px 7px 10px',
      marginRight: '15px',
    },
  },
}));

let scrollTimer;
let projectRows = []; // flatten data into rows

const timeFilterOptions = {};
timeFilterOptions[TASKS.TIME_FILTER_OFF.name] = TASKS.TIME_FILTER_OFF;
timeFilterOptions[TASKS.TIME_FILTER_1WEEK.name] = TASKS.TIME_FILTER_1WEEK;
timeFilterOptions[TASKS.TIME_FILTER_2WEEKS.name] = TASKS.TIME_FILTER_2WEEKS;
timeFilterOptions[TASKS.TIME_FILTER_1MONTH.name] = TASKS.TIME_FILTER_1MONTH;
timeFilterOptions[TASKS.TIME_FILTER_2MONTHS.name] = TASKS.TIME_FILTER_2MONTHS;
timeFilterOptions[TASKS.TIME_FILTER_3MONTHS.name] = TASKS.TIME_FILTER_3MONTHS;
const NOT_ASSIGNED = 'not assigned';
const NOT_ASSIGNED_BKGD_COLOR = '#A0A0A020';

const ProjectScheduleReport = ({
  user,
  handleProjectInfoChange,
  projects,
  workflows,
  viewState,
  dispatchUpdateViewState,
  saveWorkflows,
  handleMenu,
  dispatchUpdateUsibilityReport,
  onboarding,
  userPrefs,
  rollUpDates,
  saveCurrentProject,
  setUserColleguePref,
}) => {
  const classes = projectSummaryStyles();
  const theme = useTheme();
  const curProject = projects[viewState.currentProjectIndex];
  if (!curProject.assignedTo) curProject.assignedTo = curProject.projectManager;

  const teamSort = (a, b) => {
    if (a.email < b.email) return -1;
    if (a.email > b.email) return 1;
    return 0;
  };
  const [editor, setEditor] = React.useState({ mode: 'off' });
  const [clipboardTootip, setClipboardTootip] = React.useState('copy to clipboard');
  const getVisibleTeamMembersLookup = viewState => {
    if (
      viewState.projectScheduleReport.projectTeams &&
      viewState.projectScheduleReport.projectTeams[curProject.uuid]
    ) {
      return viewState.projectScheduleReport.projectTeams[curProject.uuid].reduce((acc, item) => {
        acc[item.email] = item.visible;
        return acc;
      }, {});
    } else {
      return [];
    }
  };
  const [visibleTeamMembersLookup, setVisibleTeamMembersLookup] = React.useState(
    getVisibleTeamMembersLookup(viewState),
  );

  const genVisibleTeamInfo = (oldVal = {}) => {
    const memberExistsInList = {};
    const teamMembers = [{ email: curProject.creator, visible: oldVal[curProject.creator] ?? true }];
    memberExistsInList[curProject.creator] = true;
    curProject.sharedWith.forEach(member => {
      if (!memberExistsInList[member]) {
        teamMembers.push({ email: member, visible: oldVal[member] ?? true });
        memberExistsInList[member] = true;
      }
    });
    if (curProject.sponsor && curProject.sponsor !== '' && !memberExistsInList[curProject.sponsor]) {
      teamMembers.push({ email: curProject.sponsor, visible: oldVal[curProject.sponsor] ?? true });
      memberExistsInList[curProject.sponsor] = true;
    }
    if (
      curProject.projectManager &&
      curProject.projectManager !== '' &&
      !memberExistsInList[curProject.projectManager]
    ) {
      teamMembers.push({
        email: curProject.projectManager,
        visible: oldVal[curProject.projectManager] ?? true,
      });
      memberExistsInList[curProject.projectManager] = true;
    }
    teamMembers.sort(teamSort);
    teamMembers.push({ email: NOT_ASSIGNED, visible: oldVal[NOT_ASSIGNED] ?? true });
    return teamMembers;
  };

  const setTeamMemberIsVisible = newTeamVisibility => {
    viewState.projectScheduleReport.projectTeams[curProject.uuid] = newTeamVisibility;
    setVisibleTeamMembersLookup(getVisibleTeamMembersLookup(viewState));
    dispatchUpdateViewState({ ...viewState });
  };

  const setTimeFilter = value => {
    viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] = value;
    dispatchUpdateViewState({ ...viewState });
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);

    if (
      !viewState.projectScheduleReport.projectTeams[curProject.uuid] ||
      !viewState.projectScheduleReport.projectTimeFilters[curProject.uuid]
    ) {
      viewState.projectScheduleReport.projectTeams[curProject.uuid] = genVisibleTeamInfo();
      viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] = TASKS.TIME_FILTER_OFF.name;
      setVisibleTeamMembersLookup(getVisibleTeamMembersLookup(viewState));
      dispatchUpdateViewState({ ...viewState });
    }

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const keyDownHandler = event => {
    if (
      event.key === 'Tab' &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      viewState.currentProjectType === NAV.TYPE_WORKFLOW
    ) {
      // handleMenu(`workflow.${viewState.currentWorkflowIndex}.Indent`);
      handleMenu(`workflow.${viewState.currentWorkflowSetId}.${viewState.currentWorkflowIndex}.Indent`);
    }
    if (
      event.key === 'Tab' &&
      !event.metaKey &&
      !event.ctrlKey &&
      event.shiftKey &&
      viewState.currentProjectType === NAV.TYPE_WORKFLOW
    ) {
      // handleMenu(`workflow.${viewState.currentWorkflowIndex}.Outdent`);
      handleMenu(`workflow.${viewState.currentWorkflowSetId}.${viewState.currentWorkflowIndex}.Outdent`);
    }
  };

  const onDragEnd = result => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;
    if (source.droppableId !== destination.droppableId) {
      return; // block cross list dropping
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const sourceProj = projects[source.index];
    projects.splice(source.index, 1);
    projects.splice(destination.index, 0, sourceProj);

    // this order is not persisted between reloads yet
  };
  const getIcon = alert => {
    return alert === null ? (
      <DateRange style={{ color: '#fff' }} className={classes.dueIcon} />
    ) : alert === 'overdue' ? (
      <DateRange style={{ color: red[500] }} className={classes.dueIcon} />
    ) : alert === 'today' || alert === 'soon' ? (
      <DateRange style={{ color: yellow[800] }} className={classes.dueIcon} />
    ) : (
      // item.alert === 'missing'
      <DateRange style={{ color: grey[500] }} className={classes.dueIcon} />
    );
  };

  const isItemVisible = (item, itemAlert, statusCategory, justMe, isTask) => {
    let overdueFilter = true;
    let todayFilter = true;
    let soonFilter = true;
    let missingFilter = true;
    let inProgressFilter = true;
    let doneFilter = true;
    let notStartedFilter = true;
    let assignedFilter = true;
    let priorityFilter = true;
    let timeFilter = true;
    if (viewState.projectScheduleReport.overdue) {
      overdueFilter = itemAlert === 'overdue';
    }
    if (viewState.projectScheduleReport.today) {
      todayFilter = itemAlert === 'today';
    }
    if (viewState.projectScheduleReport.soon) {
      soonFilter = itemAlert === 'soon';
    }
    if (viewState.projectScheduleReport.missing) {
      missingFilter = itemAlert === 'missing';
    }
    if (viewState.projectScheduleReport.statusDone) {
      doneFilter = statusCategory === STATUS.DONE;
    }
    if (viewState.projectScheduleReport.statusInProgress) {
      inProgressFilter = statusCategory === STATUS.IN_PROGRESS;
    }
    if (viewState.projectScheduleReport.statusNotStarted) {
      notStartedFilter = statusCategory === STATUS.NOT_STARTED;
    }
    if (statusCategory === STATUS.NOT_STARTED && item.expectedEndDate === '') {
      notStartedFilter = false;
    }
    if (item === curProject) {
      if (item.projectManager !== '') {
        assignedFilter = visibleTeamMembersLookup[item.projectManager] ?? false;
      } else {
        assignedFilter = visibleTeamMembersLookup[NOT_ASSIGNED] ?? false;
      }
    } else {
      if (item.assignedTo !== '') {
        assignedFilter = visibleTeamMembersLookup[item.assignedTo] ?? false;
      } else {
        assignedFilter = visibleTeamMembersLookup[NOT_ASSIGNED] ?? false;
      }
    }
    if (viewState.projectScheduleReport.priority) {
      priorityFilter = item.isPriority;
    }
    if (isTask) {
      if (
        viewState.projectScheduleReport.projectTimeFilters &&
        viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] &&
        viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] !== TASKS.TIME_FILTER_OFF.name
      ) {
        const days =
          timeFilterOptions[viewState.projectScheduleReport.projectTimeFilters[curProject.uuid]].time;
        const startTestRangeMoment = moment();
        const endTestRangeMoment = moment().add(days, 'days');
        const startDate =
          item.actualStartDate === ''
            ? item.expectedStartDate === ''
              ? null
              : item.expectedStartDate
            : item.actualStartDate;
        const endDate =
          item.actualEndDate === ''
            ? item.expectedEndDate === ''
              ? null
              : item.expectedEndDate
            : item.actualEndDate;
        if (startDate && endDate) {
          const taskStartMoment = moment(startDate);
          const taskEndMoment = moment(endDate);
          if (
            !(taskStartMoment.isBefore(endTestRangeMoment) && taskEndMoment.isAfter(startTestRangeMoment))
          ) {
            timeFilter = false;
          }
        }
        if (startDate && !endDate) {
          const taskStartMoment = moment(startDate);
          if (taskStartMoment.isAfter(endTestRangeMoment)) {
            timeFilter = false;
          }
        }
        if (!startDate && endDate) {
          const taskEndMoment = moment(endDate);
          if (taskEndMoment.isBefore(startTestRangeMoment)) {
            timeFilter = false;
          }
        }
        if (!startDate && !endDate) {
          timeFilter = false;
        }
      }
    } else {
      if (
        viewState.projectScheduleReport.projectTimeFilters &&
        viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] !== TASKS.TIME_FILTER_OFF.name
      ) {
        timeFilter = false;
      }
    }
    if (item === curProject) {
      return assignedFilter;
    } else {
      return (
        overdueFilter &&
        todayFilter &&
        soonFilter &&
        missingFilter &&
        inProgressFilter &&
        doneFilter &&
        notStartedFilter &&
        priorityFilter &&
        assignedFilter &&
        timeFilter
      );
    }
  };

  const timeFilterOff = item => {
    if (
      viewState.projectScheduleReport.projectTimeFilters &&
      viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] &&
      viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] !== TASKS.TIME_FILTER_OFF.name
    ) {
      return false;
    }
    return true;
  };

  const withinTimeFilter = item => {
    if (
      viewState.projectScheduleReport.projectTimeFilters &&
      viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] &&
      viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] !== TASKS.TIME_FILTER_OFF.name
    ) {
      const days =
        timeFilterOptions[viewState.projectScheduleReport.projectTimeFilters[curProject.uuid]].time;
      const startTestRangeMoment = moment();
      const endTestRangeMoment = moment().add(days, 'days');
      const startDate =
        item.actualStartDate === ''
          ? item.expectedStartDate === ''
            ? null
            : item.expectedStartDate
          : item.actualStartDate;
      const endDate =
        item.actualEndDate === ''
          ? item.expectedEndDate === ''
            ? null
            : item.expectedEndDate
          : item.actualEndDate;
      if (startDate && endDate) {
        const taskStartMoment = moment(startDate);
        const taskEndMoment = moment(endDate);
        if (!(taskStartMoment.isBefore(endTestRangeMoment) && taskEndMoment.isAfter(startTestRangeMoment))) {
          return false;
        }
      }
      if (startDate && !endDate) {
        const taskStartMoment = moment(startDate);
        if (taskStartMoment.isAfter(endTestRangeMoment)) {
          return false;
        }
      }
      if (!startDate && endDate) {
        const taskEndMoment = moment(endDate);
        if (taskEndMoment.isBefore(startTestRangeMoment)) {
          return false;
        }
      }
      if (!startDate && !endDate) {
        return false;
      }
    }
    return true;
  };
  // falseProjectData.forEach(proj => {
  const alertCounts = {
    ['overdue']: 0,
    ['today']: 0,
    ['soon']: 0,
    ['missing']: 0,
    ['priority']: 0,
    [STATUS.IN_PROGRESS]: 0,
    [STATUS.DONE]: 0,
    [STATUS.NOT_STARTED]: 0,
  };

  const genProjectRows = curProject => {
    let projectRows = []; // flatten data into rows
    if (curProject) {
      let numVisibleDels = 0;
      let itemAlert;
      let statusCat;
      let dateValues;
      let itemVisible;
      let justMe;
      let personIsVisible;
      const delsCopy = curProject.deliverables.slice().reverse();
      delsCopy.forEach((del, drIndex) => {
        const dIndex = curProject.deliverables.length - 1 - drIndex;
        let numVisibleWPs = 0;
        const wpsCopy = del.workPackages.slice().reverse();
        wpsCopy.forEach((wp, wrIndex) => {
          const wIndex = del.workPackages.length - 1 - wrIndex;
          let numVisibleTasks = 0;
          // const tasksCopy = wp.tasks.slice().reverse();
          // tasksCopy.forEach((task, trIndex) => {
          //   const tIndex = wp.tasks.length - 1 - trIndex;
          //   itemAlert = utils.getAlertInfo(task, curProject.numWeeksInSprint);
          //   statusCat = utils.getStatusCategory(task);
          //   if (statusCat === STATUS.DONE) itemAlert = { alertType: null, text: null };
          //   dateValues = utils.getDateValues(task, statusCat);
          //   personIsVisible = (!task.assignedTo || task.assignedTo === "") ? visibleTeamMembersLookup[NOT_ASSIGNED] : visibleTeamMembersLookup[task.assignedTo];
          //   if(personIsVisible && withinTimeFilter(task)) {
          //     alertCounts[itemAlert.alertType]++;
          //     alertCounts[statusCat]++;
          //     if (task.isPriority) {
          //       alertCounts['priority']++;
          //     }
          //   }
          //   if (isItemVisible(task, itemAlert.alertType, statusCat, justMe, true)) {
          //     numVisibleTasks++;
          //     projectRows.unshift({
          //       text: `${task.name}`,
          //       dueText: itemAlert.text ? itemAlert.text : '',
          //       icon: getIcon(itemAlert.alertType),
          //       dateText: dateValues.dateText,
          //       dateLateIconColor: dateValues.dateLateIconColor,
          //       dateTooltipTitle: dateValues.dateTooltipTitle,
          //       level: 3,
          //       link: utils.getWBSLink('t', viewState.currentProjectIndex, dIndex, wIndex, tIndex),
          //       priority: task.isPriority,
          //       status: task.status,
          //       statusCategory: statusCat,
          //       statusColor: utils.getStatusColor(task.status),
          //       assignedTo: viewState.projectScheduleReport.justMe ? null : task.assignedTo,
          //       justName: false,
          //       showAvatar: !viewState.projectScheduleReport.justMe,
          //       item: task,
          //       type: 'task',
          //       uuid: task.uuid,
          //     });
          //   }
          // });
          justMe = wp.assignedTo === user.email;
          itemAlert = utils.getAlertInfo(wp, curProject.numWeeksInSprint);
          statusCat = utils.getStatusCategory(wp);
          if (statusCat === STATUS.DONE) itemAlert = { alertType: null, text: null };
          dateValues = utils.getDateValues(wp, statusCat);
          // if(wp.name === "junk stuff") {
          //   debugger;
          // }
          itemVisible = isItemVisible(wp, itemAlert.alertType, statusCat, justMe);
          personIsVisible =
            !wp.assignedTo || wp.assignedTo === ''
              ? visibleTeamMembersLookup[NOT_ASSIGNED]
              : visibleTeamMembersLookup[wp.assignedTo];
          if (personIsVisible && timeFilterOff()) {
            alertCounts[itemAlert.alertType]++;
            if (wp.isPriority) {
              alertCounts['priority']++;
            }
          }
          // if (numVisibleTasks > 0 || itemVisible) {
          if (itemVisible) {
            numVisibleWPs++;
            projectRows.unshift({
              text: `${wp.name}`,
              dueText: itemAlert.text ? itemAlert.text : '',
              icon: getIcon(itemAlert.alertType),
              dateText: dateValues.dateText,
              dateLateIconColor: dateValues.dateLateIconColor,
              dateTooltipTitle: dateValues.dateTooltipTitle,
              level: 2,
              link: utils.getWBSLink('w', viewState.currentProjectIndex, dIndex, wIndex, 0),
              priority: wp.isPriority,
              status: statusCat,
              statusCategory: statusCat,
              statusColor: utils.getStatusColor(wp.status),
              assignedTo: viewState.projectScheduleReport.justMe ? null : wp.assignedTo,
              justName: !itemVisible,
              showAvatar: !viewState.projectScheduleReport.justMe,
              item: wp,
              type: 'task group',
              uuid: wp.uuid,
            });
          }
        });
        // if (numVisibleWPs > 0 ) {
        justMe = del.assignedTo === user.email;
        itemAlert = utils.getAlertInfo(del, curProject.numWeeksInSprint);
        statusCat = utils.getStatusCategory(del);
        if (statusCat === STATUS.DONE) itemAlert = { alertType: null, text: null };
        dateValues = utils.getDateValues(del, statusCat);
        itemVisible = isItemVisible(del, itemAlert.alertType, statusCat, justMe);
        personIsVisible =
          !del.assignedTo || del.assignedTo === ''
            ? visibleTeamMembersLookup[NOT_ASSIGNED]
            : visibleTeamMembersLookup[del.assignedTo];
        if (personIsVisible && timeFilterOff()) {
          alertCounts[itemAlert.alertType]++;
          if (del.isPriority) {
            alertCounts['priority']++;
          }
        }
        if (numVisibleWPs > 0 || itemVisible) {
          numVisibleDels++;
          projectRows.unshift({
            text: `${del.name}`,
            dueText: itemAlert.text ? itemAlert.text : '',
            icon: getIcon(itemAlert.alertType),
            dateText: dateValues.dateText,
            dateLateIconColor: dateValues.dateLateIconColor,
            dateTooltipTitle: dateValues.dateTooltipTitle,
            level: 1,
            link: utils.getWBSLink('d', viewState.currentProjectIndex, dIndex, 0, 0),
            priority: del.isPriority,
            status: statusCat,
            statusCategory: statusCat,
            statusColor: utils.getStatusColor(del.status),
            assignedTo: viewState.projectScheduleReport.justMe ? null : del.assignedTo,
            justName: !itemVisible,
            showAvatar: !viewState.projectScheduleReport.justMe,
            item: del,
            type: 'deliverable',
            uuid: del.uuid,
          });
        }
      });
      if (numVisibleDels > 0) {
        itemAlert = utils.getAlertInfo(curProject, curProject.numWeeksInSprint);
        dateValues = utils.getDateValues(curProject, statusCat);
        statusCat = utils.getStatusCategory(curProject);
        itemVisible = isItemVisible(curProject, itemAlert.alertType, statusCat, justMe);
        projectRows.unshift({
          text: `${curProject.name}`,
          dueText: itemAlert.text ? itemAlert.text : '',
          icon: getIcon(itemAlert.alertType),
          dateText: dateValues.dateText,
          dateLateIconColor: dateValues.dateLateIconColor,
          dateTooltipTitle: dateValues.dateTooltipTitle,
          level: 0,
          link: utils.getWBSLink('p', viewState.currentProjectIndex, 0, 0, 0),
          status: statusCat,
          uuid: curProject.uuid,
          justName: !itemVisible,
          item: curProject,
          type: 'project',
          assignedTo: viewState.projectScheduleReport.justMe ? null : curProject.projectManager,
          showAvatar: !viewState.projectScheduleReport.justMe,
        });
      }
    }
    return projectRows;
  };
  const projectRows = genProjectRows(curProject);

  const numStrOverdue = alertCounts['overdue'] === 0 ? '' : `(${alertCounts['overdue']})`;
  const numStrDueToday = alertCounts['today'] === 0 ? '' : `(${alertCounts['today']})`;
  const numStrSoon = alertCounts['soon'] === 0 ? '' : `(${alertCounts['soon']})`;
  const numStrMissingDate = alertCounts['missing'] === 0 ? '' : `(${alertCounts['missing']})`;
  const numStrPriority = alertCounts['priority'] === 0 ? '' : `(${alertCounts['priority']})`;
  const numStrInProgress =
    alertCounts[STATUS.IN_PROGRESS] === 0 ? '' : `(${alertCounts[STATUS.IN_PROGRESS]})`;
  const numStrDone = alertCounts[STATUS.DONE] === 0 ? '' : `(${alertCounts[STATUS.DONE]})`;
  const numStrNotStarted =
    alertCounts[STATUS.NOT_STARTED] === 0 ? '' : `(${alertCounts[STATUS.NOT_STARTED]})`;
  const textOverdue = `Overdue ${numStrOverdue}`;
  const textDueToday = `Due Today ${numStrDueToday}`;
  const textSoon = `Due Soon ${numStrSoon}`;
  const textMissingDate = `Date Missing ${numStrMissingDate}`;
  const textPriority = `Priority ${numStrPriority}`;
  const textInProgress = `In Progress ${numStrInProgress}`;
  const textDone = `Done ${numStrDone}`;
  const textNotStarted = `Not Started ${numStrNotStarted}`;
  const totalItems =
    alertCounts['overdue'] + alertCounts['today'] + alertCounts['soon'] + alertCounts['missing'];

  const makePDF = () => {
    const projectRows = genProjectRows(curProject);
    // delete projectRows[0]; // remove project row
    const tableBody = [['Work Component', '!', 'Date', 'Assigned', 'Status', 'Alert']];
    projectRows.forEach(row => {
      const textLen = 54 - (row.level - 1) * 3;
      const indent = row.level * 10;
      const bottom = [0, 0, 2, 4];
      const top = [0, 5, 4, 0];
      const assignedToBits = row.assignedTo.split('@');
      const rowPriorityStr = row.priority ? '!' : '';
      tableBody.push([
        {
          // text: `${row.level === 3 ? '-  ' : ""}${row.text.substring(0,textLen)}`,
          text: `${row.text.substring(0, textLen)}`,
          marginLeft: indent,
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
        {
          // text: row.level === 2 ? rowPriorityStr : '',
          text: rowPriorityStr,
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
        {
          // text: row.level === 2 ? row.dateText : '',
          text: row.dateText,
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
        {
          // text: row.level === 3 ? assignedToBits[0].substring(0,11) : '',
          text: assignedToBits[0].substring(0, 11),
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
        {
          // text: row.level === 2 ? STATUS.statusString[row.status] : '',
          text: STATUS.statusString[row.status],
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
        {
          // text: row.level === 2 ? row.dueText : '',
          text: row.dueText,
          marginTop: top[row.level],
          marginBottom: bottom[row.level],
        },
      ]);
    });
    const creationDate = moment().format('GGGG-MM-DD');
    const docDefinition = {
      // a string or { width: number, height: number }
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [40, 60, 40, 60],
      header: {
        columns: [
          { text: `Work for Project:  ${curProject.name}`, alignment: 'left', marginTop: 20, marginLeft: 40 },
          { text: `${creationDate}`, alignment: 'right', marginTop: 20, marginRight: 60 },
        ],
      },
      footer: (currentPage, pageCount) => {
        return {
          text: `Page ${currentPage.toString()} of ${pageCount}`,
          alignment: 'center',
          marginBottom: -20,
        };
      },

      content: [
        {
          layout: 'headerLineOnly', // - for other options see https://pdfmake.github.io/docs/0.1/document-definition-object/tables/
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [320, 20, 70, 83, 70, 120],
            body: tableBody,
          },
        },
      ],
    };
    // create the window before the callback
    var win = window.open('', '_blank');
    pdfMake.createPdf(docDefinition).open({}, win);
  };

  const renderProjectRow = (r, index) => {
    const lMargin = r.level * 20;
    const fWeight = r.level === 0 ? 600 : 500;
    const mTop = r.level === 0 ? 15 : 2;
    const avatarId = r.assignedTo ? r.assignedTo.substring(0, 1).toUpperCase() : null;
    let collegueColor;
    const statusTextColor = r.justName
      ? '#fff'
      : r.level === 3
      ? r.status === STATUS.BLOCKED || r.status === STATUS.DONE
        ? '#eee'
        : '#222'
      : '#888';
    const statusBGColor = r.level < 3 ? '#fff' : r.statusColor;
    if (r.assignedTo) {
      if (userPrefs.collegues[r.assignedTo]) {
        collegueColor = userPrefs.collegues[r.assignedTo].color;
      }
      if (!collegueColor) {
        collegueColor = utils.randColor();
        setTimeout(() => {
          setUserColleguePref({
            email: r.assignedTo,
            color: collegueColor,
          });
        });
      }
      if (!visibleTeamMembersLookup[r.assignedTo]) {
        collegueColor = collegueColor.substring(0, 7) + '28';
      }
    }

    let subcomponentsHaveNoDateSet = false; // TODO in future calc this with date rollup and store with item - avoid this extra processing
    if (r.level === 0) {
      // project
      subcomponentsHaveNoDateSet =
        r.statusCategory === STATUS.NOT_STARTED
          ? utils.subcomponetsHaveNoDateSet(r.item, 'start', 'P')
          : r.statusCategory === STATUS.IN_PROGRESS
          ? utils.subcomponetsHaveNoDateSet(r.item, 'end', 'P')
          : false;
    }
    if (r.level === 1) {
      // deliverable
      subcomponentsHaveNoDateSet =
        r.statusCategory === STATUS.NOT_STARTED
          ? utils.subcomponetsHaveNoDateSet(r.item, 'start', 'D')
          : r.statusCategory === STATUS.IN_PROGRESS
          ? utils.subcomponetsHaveNoDateSet(r.item, 'end', 'D')
          : false;
    }
    if (r.level === 2) {
      // workpackage
      subcomponentsHaveNoDateSet =
        r.statusCategory === STATUS.NOT_STARTED || r.statusCategory === STATUS.IN_PROGRESS
          ? utils.subcomponetsHaveNoDateSet(r.item, 'end', 'W')
          : false;
      // r.statusCategory === STATUS.NOT_STARTED
      //   ? utils.subcomponetsHaveNoDateSet(r.item, 'start', 'W')
      //   : r.statusCategory === STATUS.IN_PROGRESS
      //   ? utils.subcomponetsHaveNoDateSet(r.item, 'end', 'W')
      //   : false;
    }

    let editorDateLabel;
    let editorDate;
    if (editor.mode === 'date' && r.uuid === editor.item.uuid) {
      switch (
        r.statusCategory // TODO in future calc this with date rollup and store with item - avoid this extra processing
      ) {
        default:
        case STATUS.NOT_NEEDED:
          break;
        case STATUS.NOT_STARTED:
          editorDateLabel = 'Expected Start Date';
          editorDate = r.item.expectedStartDate;
          break;
        case STATUS.IN_PROGRESS:
          editorDateLabel = 'Expected End Date';
          editorDate = r.item.expectedEndDate;
          break;
        case STATUS.DONE:
          editorDateLabel = 'End Date';
          editorDate = r.item.actualEndDate;
          break;
      }
    }
    if (r.level === 0) console.log(`+++++++++++++++ ${r.justName}  "${r.dateText}"`);

    return (
      <div className={classes.urProjectRow} style={{ marginTop: mTop }}>
        <Typography
          onClick={e => {
            const topStack = r.link.stack[r.link.stack.length - 1];

            Object.assign(viewState, {
              currentProjectType: NAV.TYPE_PROJECT,
              currentProjectIndex: r.link.projIndex,
              curWindow: topStack.screen,
              detailsTabIndex: 0,
              currentDeliverable: topStack.item.d > 0 ? topStack.item.d : 0,
              currentWorkPackage: topStack.item.w > 0 ? topStack.item.w : 0,
              currentTask: topStack.item.t > 0 ? topStack.item.t : 0,
              wbsStack: r.link.stack,
            });
            dispatchUpdateViewState(viewState);
          }}
          variant={'h6'}
          className={classes.alertComponentText}
          style={{
            fontWeight: fWeight,
            marginLeft: lMargin,
          }}
        >
          {r.text}
        </Typography>
        {!r.justName && r.priority && <PriorityIcon />}

        {r.dateText && !r.justName && (
          <div
            className={classes.taskDate}
            onClick={e => {
              if (r.level === 3 || subcomponentsHaveNoDateSet) {
                setEditor({
                  mode: 'date',
                  point: [e.pageX - 193, e.pageY - 188],
                  item: r,
                });
              }
            }}
          >
            <Tooltip title={editor.mode === 'date' ? '' : r.dateTooltipTitle}>
              <Typography
                variant="overline"
                className={classes.dateString}
                style={{ color: r.dateLateIconColor }}
              >
                {r.dateText}
              </Typography>
            </Tooltip>
          </div>
        )}
        {avatarId ? (
          <Tooltip title={r.assignedTo}>
            <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
              <Typography
                className={classes.assignedToText}
                onClick={e => {
                  // if(r.type !== 'project') {  // can't change project assignment here... always assigned to project manager
                  setEditor({
                    mode: 'person',
                    point: [e.pageX - 280, e.pageY - 200],
                    item: r,
                  });
                  // }
                }}
              >
                {avatarId}
              </Typography>
            </div>
          </Tooltip>
        ) : (
          <div className={classes.assignedToAvatar} style={{ backgroundColor: NOT_ASSIGNED_BKGD_COLOR }}>
            <Person
              className={classes.notAssignedTo}
              onClick={e => {
                // if(r.type !== 'project') {  // can't change project assignment here... always assigned to project manager
                setEditor({
                  mode: 'person',
                  point: [e.pageX - 280, e.pageY - 200],
                  item: r,
                });
                // }
              }}
            />
          </div>
        )}
        {r.level === 3 ? (
          <div
            style={{
              marginLeft: 10,
              marginTop: -3,
            }}
          >
            <StatusButton taskCard={{ task: r.item }} />
          </div>
        ) : (
          <Button
            size={'small'}
            onClick={e => {}}
            disabled={r.level < 3}
            variant="contained"
            style={{
              color: statusTextColor, //APP.palette.getContrastText(r.statusColor),
              backgroundColor: statusBGColor,
              width: '120px',
              marginLeft: 10,
              marginTop: -2,
            }}
          >
            {/* {r.status === STATUS.NOT_STARTED ? 'Not Scheduled' : STATUS.statusString[r.status]} */}
            {STATUS.statusString[r.status]}
          </Button>
        )}
        {!r.justName ? (
          <div className={classes.alertContainer}>
            {r.icon}
            <Typography variant={'h6'} className={classes.dueText}>
              {r.dueText}
            </Typography>
          </div>
        ) : (
          <div className={classes.alertContainer}>
            <Typography variant={'h6'} className={classes.blankDueText}>
              {' '}
            </Typography>
          </div>
        )}
        {/* {editor.mode === 'date' && r.uuid === editor.item.uuid && (
          <div className={classes.editorBox} style={{ left: editor.point[0], top: editor.point[1] }}>
            <TextField
              id="outlined-step-actual-start"
              name="actualStartDate"
              type="date"
              autoFocus
              variant="outlined"
              label={editorDateLabel}
              value={editorDate}
              className={classes.editor}
              onChange={e => {
                switch (r.statusCategory) {
                  default:
                  case STATUS.NOT_STARTED:
                    r.item.expectedStartDate = e.target.value;
                    break;
                  case STATUS.IN_PROGRESS:
                    r.item.expectedEndDate = e.target.value;
                    break;
                  case STATUS.DONE:
                    r.item.actualEndDate = e.target.value;
                    break;
                }
                setEditor({
                  mode: 'date',
                  point: editor.point,
                  item: r,
                });
              }}
              onKeyPress={ev => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  ev.stopPropagation()
                  rollUpDates();
                  saveCurrentProject();
                  setEditor({ mode: 'off' });

                }
              }}
              onBlur={ev => {
                ev.stopPropagation()
                rollUpDates();
                saveCurrentProject();
                setEditor({ mode: 'off' });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )} */}
      </div>
    );
  };

  const generateButtons = (keyArray, namesArray = null, radioButtons = false) => {
    if (!namesArray) namesArray = keyArray.slice();
    return keyArray.reduce((acc, key, index) => {
      if (alertCounts[keyArray[index]]) {
        acc.push(
          <div
            className={classes.statusFilterButton}
            style={{
              backgroundColor: viewState.projectScheduleReport[key]
                ? theme.palette.secondary.main
                : theme.palette.secondary.light,
              marginLeft: '5px',
            }}
            onClick={e => {
              let newProjectScheduleReport = { ...viewState.projectScheduleReport };
              if (radioButtons) {
                keyArray.forEach(key => {
                  newProjectScheduleReport[key] = false;
                });
              }
              newProjectScheduleReport[key] = !viewState.projectScheduleReport[key];
              dispatchUpdateViewState({ ...viewState, projectScheduleReport: newProjectScheduleReport });
            }}
          >
            <Typography
              className={classes.statusFilterButtonText}
              style={{
                color: viewState.projectScheduleReport[key] ? '#FFF' : '#AAA',
              }}
            >
              {namesArray[index].substring(0, 1).toUpperCase() + namesArray[index].substring(1)}
            </Typography>
            <Typography
              className={classes.statusFilterCountText}
              style={{
                color: viewState.projectScheduleReport[key] ? '#FFF' : '#AAA',
              }}
            >
              {`(${alertCounts[keyArray[index]]})`}
            </Typography>
          </div>,
        );
      }
      return acc;
    }, []);
  };

  const statusButtons = generateButtons(
    ['statusNotStarted', 'statusInProgress', 'statusDone'],
    [STATUS.NOT_STARTED, STATUS.IN_PROGRESS, STATUS.DONE],
    true,
  );
  // const filterButtons = generateButtons([
  //   'Past Work Periods',
  //   'today',
  //   'Due Soon',
  //   'Not Scheduled',
  //   'priority',
  // ]);
  const filterButtons = generateButtons(
    ['overdue', 'today', 'soon', 'missing', 'priority'],
    ['Overdue', 'today', 'Current', 'Schedule !', 'priority'],
  );

  const generateTeamMemberAvatarButton = (teamMemberEmail, visible) => {
    if (
      teamMemberEmail !== NOT_ASSIGNED &&
      !userPrefs.collegues[teamMemberEmail] &&
      !userPrefs.collegues[teamMemberEmail].color
    ) {
      userPrefs.collegues[teamMemberEmail] = utils.randColor();
      setTimeout(() => {
        setUserColleguePref({
          email: teamMemberEmail,
          color: utils.randColor(),
        });
      });
    }
    const avatarBkgdColor =
      teamMemberEmail === NOT_ASSIGNED || !userPrefs.collegues[teamMemberEmail]?.color
        ? visible
          ? NOT_ASSIGNED_BKGD_COLOR.substring(0, 7) + 'D0'
          : NOT_ASSIGNED_BKGD_COLOR.substring(0, 7) + '30'
        : visible
        ? userPrefs.collegues[teamMemberEmail].color
        : userPrefs.collegues[teamMemberEmail].color.substring(0, 7) + '30';
    return (
      <Tooltip title={teamMemberEmail}>
        <div
          className={classes.assignedToAvatar}
          style={{ backgroundColor: avatarBkgdColor, marginLeft: '10px' }}
          onClick={e => {
            const newTeamVisibility = viewState.projectScheduleReport.projectTeams[curProject.uuid]?.slice();
            const item = newTeamVisibility.find(item => item.email === teamMemberEmail);
            item.visible = !item.visible;
            setTeamMemberIsVisible(newTeamVisibility);
          }}
        >
          <Typography className={classes.assignedToAvatarText}>
            {teamMemberEmail === NOT_ASSIGNED ? '-' : teamMemberEmail.substring(0, 1).toUpperCase()}
          </Typography>
        </div>
      </Tooltip>
    );
  };
  const teamVisibility =
    viewState.projectScheduleReport.projectTeams &&
    viewState.projectScheduleReport.projectTeams[curProject.uuid]
      ? viewState.projectScheduleReport.projectTeams[curProject.uuid]
      : [];
  const timeFilter =
    viewState.projectScheduleReport.projectTimeFilters &&
    viewState.projectScheduleReport.projectTimeFilters[curProject.uuid]
      ? viewState.projectScheduleReport.projectTimeFilters[curProject.uuid]
      : TASKS.TIME_FILTER_OFF.name;

  return (
    <div className={classes.rootContainer}>
      <div
        style={{
          position: 'absolute',
          top: '66px',
          bottom: 0,
          right: 0,
          left: 50,
          maxHeight: 'calc(100vh -  66px)',
          overflow: 'hidden',
          backgroundColor: '#BBB6',
          // backgroundColor: 'red',
          zIndex: 20,
        }}
      >
        <div
          style={{
            margin: '30px',
            padding: '10px',
            paddingBottom: '30px',
            marginLeft: '60px',
            marginRight: '50px',
            border: '8px solid #BBB',
            height: 'calc(100vh - 160px)',
            overflow: 'hidden',
            borderRadius: '15px',
            backgroundColor: 'white',
            flex: 1,
          }}
        >
          <ContentWrapper
            backToWindow={NAV.SPRINTVIEW}
            backCallback={() => {}}
            viewState={viewState}
            dispatchUpdateViewState={dispatchUpdateViewState}
            title="Schedule"
            headerMarginTop={'10px'}
            titleChildren={
              <Fragment>
                <Tooltip title={'save as PDF'}>
                  <Button
                    size={'small'}
                    onClick={() => {
                      makePDF();
                    }}
                    disabled={projectRows.length === 0}
                    className={classes.pdfButton}
                  >
                    <PictureAsPdf style={{ height: '50px', width: '36px' }} />
                  </Button>
                </Tooltip>
              </Fragment>
            }
          >
            <div className={classes.contentContainer}>
              <div className={classes.buttonContainer}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  {teamVisibility.map(item => {
                    return generateTeamMemberAvatarButton(item.email, item.visible);
                  })}
                  <Select
                    labelId="time-filter"
                    id="priority-select"
                    name="timeFilter"
                    classes={{ root: classes.menuItem }}
                    className={classes.select}
                    disabled={false}
                    value={timeFilter}
                    onChange={e => {
                      // setAccountType(e.currentTarget.innerText);
                      setTimeFilter(e.currentTarget.innerText);
                    }}
                    variant="outlined"
                    label="Plan Type"
                  >
                    <MenuItem className={classes.menuItem} value={TASKS.TIME_FILTER_OFF.name}>
                      {TASKS.TIME_FILTER_OFF.name}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TASKS.TIME_FILTER_1WEEK.name}>
                      {TASKS.TIME_FILTER_1WEEK.name}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TASKS.TIME_FILTER_2WEEKS.name}>
                      {TASKS.TIME_FILTER_2WEEKS.name}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TASKS.TIME_FILTER_1MONTH.name}>
                      {TASKS.TIME_FILTER_1MONTH.name}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TASKS.TIME_FILTER_2MONTHS.name}>
                      {TASKS.TIME_FILTER_2MONTHS.name}
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={TASKS.TIME_FILTER_3MONTHS.name}>
                      {TASKS.TIME_FILTER_3MONTHS.name}
                    </MenuItem>
                  </Select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <div className={classes.statusButtonRow}>{filterButtons}</div>
                  <div className={classes.statusButtonRow}>{statusButtons}</div>
                </div>
              </div>
              <div className={classes.parentContainer}>
                {projectRows.length === 0 ? (
                  <div className={classes.loadingIndicator}>
                    <Typography
                      variant={'h6'}
                      className={classes.attentionMessage}
                      style={{ fontWeight: 600 }}
                    >
                      {'Toggle the filter button(s) above to display items.'}
                    </Typography>
                  </div>
                ) : (
                  <div
                    className={classes.reportlist}
                    ref={r => {
                      if (r) {
                        r.scrollTop = viewState.scrollTop.projectSchedule
                          ? viewState.scrollTop.projectSchedule
                          : 0;
                      }
                    }}
                    onScroll={e => {
                      if (e.currentTarget.scrollTop !== viewState.scrollTop.projectSchedule) {
                        if (scrollTimer) {
                          clearTimeout(scrollTimer);
                        }
                        const scrollTop = e.currentTarget.scrollTop;
                        scrollTimer = setTimeout(e => {
                          // debounce scoll values
                          viewState.scrollTop.projectSchedule = scrollTop;
                          // console.log(
                          //   `scroll ${viewState.scrollTop.projectSchedule}`,
                          // );
                          updateViewState(viewState);
                          scrollTimer = undefined;
                        }, 50);
                      }
                    }}
                  >
                    <FlatList
                      className={classes.flatList}
                      list={projectRows}
                      renderItem={renderProjectRow}
                      renderOnScroll
                    />
                    <div style={{ height: '400px' }} />
                  </div>
                )}
              </div>
            </div>
            {editor.mode === 'person' && (
              <NamePickerDialog
                open={true}
                email={editor.item ? editor.item.item.assignedTo : ''}
                label={
                  editor.item && editor.item.item.assignedTo !== ''
                    ? `${editor.item.type} currently assigned to`
                    : `Assign ${editor.item.type} to`
                }
                toggle={() => {
                  setEditor({ mode: 'off' });
                }}
                team={curProject.sharedWith}
                projects={projects}
                user={user}
                viewState={viewState}
                userPrefs={userPrefs}
                saveCurrentProject={() => {
                  saveCurrentProject();
                }}
                selectTeamMember={newAssignedTo => {
                  if (editor.item?.item === curProject) {
                    if (newAssignedTo !== editor.item?.item.projectManager) {
                      editor.item.item.projectManager = newAssignedTo;
                      editor.item.item.assignedTo = newAssignedTo;
                      saveCurrentProject();
                      visibleTeamMembersLookup[newAssignedTo] = true;
                      setTeamMemberIsVisible(genVisibleTeamInfo(visibleTeamMembersLookup));
                    }
                  } else {
                    if (newAssignedTo !== editor.item?.item.assignedTo) {
                      editor.item.item.assignedTo = newAssignedTo;
                      saveCurrentProject();
                      visibleTeamMembersLookup[newAssignedTo] = true;
                      setTeamMemberIsVisible(genVisibleTeamInfo(visibleTeamMembersLookup));
                    }
                  }
                  setEditor({ mode: 'off' });
                }}
                closeDialog={() => {
                  setEditor({ mode: 'off' });
                }}
              />
            )}
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => {
    return {
      user: state.user,
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      firebase: state.firebase,
      onboarding: state.onboarding,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    saveCurrentProject,
    saveWorkflows,
    dispatchUpdateViewState,
    dispatchUpdateUsibilityReport,
    rollUpDates,
    setUserColleguePref,
  },
)(ProjectScheduleReport);
