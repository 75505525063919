// @flow
/* eslint-disable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { withStyles, makeStyles, Tooltip, Badge, Typography } from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFileOutlined';
import GridOn from '@material-ui/icons/GridOn';
// import Reorder from '@material-ui/icons/ReorderOutlined';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import SplitWPDialog from './SplitWPDialog';
import DraggableDelColumn from './DraggableDelColumn';
import DraggableWPColumn from './DraggableWPColumn';
import OnboardingDialog from '../OnboardingDialog';
import DetailsSection from '../work_package_details/DetailsSection';

import { ContentWrapper } from '../styled_components/ContentWrapper';
import { AddButton } from '../styled_components/AddButton';
import { TeamModal } from '../project_overview/TeamModal';
import { AttachmentsTable } from '../attachments/AttachmentsTable';

import {
  handleMenu,
  reorderColumns,
  handleCardDrop,
  dispatchUpdateViewState,
} from '../../data/actions/userInterface';
import { togglePinVisibility, toggleDetailPinVisibility } from '../../data/actions/app';
import { saveCurrentProject, updateViewState } from '../../data/actions/userInterface';

import * as utils from '../../utils/generalUtilities.js';
import * as NAV from '../../constants/navigation';
import * as STATUS from '../../constants/status';
import * as USER from '../../constants/user';

const styles = {
  root: {
    paddingBottom: '8px', // To give some breathing room with the scroll bar
    display: 'flex',
    justifyContent: 'flex-start',
    overflowX: 'scroll',
    overflowY: 'hidden',
    maxHeight: 'calc(100% - 120px)',
  },
  collapsed: {
    height: '0',
  },
  addButton: {
    marginLeft: 0,
    marginRight: 20,
    color: '#FFF',
  },
  attachIcon: {
    height: '30px',
    width: '30px',
    border: '2px solid #aaa',
    borderRadius: '5px',
    marginLeft: '30px',
    padding: '2px',
    color: '#888',
    backgroundColor: '#f6f6f6',
  },
};

const dbuttonStyles = makeStyles(theme => ({
  iconButton: {
    color: theme.palette.primary.contrastText,
  },
  arrow: {
    transition: 'transform 0.3s ease-in-out',
    transform: ({ isExpanded }) => (isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  fab: {
    color: 'white',
  },
  listIcon: {
    minWidth: '30px',
  },
  addButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.light,
    margin: '0 5px',
    '&:hover': {
      background: theme.palette.secondary.menu,
      color: theme.palette.secondary.light,
    },
    '& svg': {
      fontSize: 22, // sets size of IconButton and scales the font inside
    },
    boxShadow: 1,
  },
  switch: {
    inputProps: { 'aria-label': 'show/hide hidden items' },
  },
  buttonContainer: {
    width: 120,
    height: '40px',
    marginRight: 30,
    alignSelf: 'flex-start',
  },
}));
let scrollTimer;
let scrollableDivElement;
class WBS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamModel: false,
      attachmentsOpen: false,
      welcomeDialog: Boolean(
        props.userPrefs.onboarding && props.userPrefs.onboarding[USER.ONBOARDING_WELCOME_DIALOG],
      ),
      exampleProjectDialog: Boolean(
        props.userPrefs.onboarding && props.userPrefs.onboarding[USER.ONBOARDING_EXAMPLE_PROJECT_DIALOG],
      ),
      onboardingDialog: Boolean(
        props.userPrefs.onboarding && props.userPrefs.onboarding[USER.ONBOARDING_WBS_DIALOG],
      ),
      onboardingZoomedDialog: Boolean(
        props.userPrefs.onboarding && props.userPrefs.onboarding[USER.ONBOARDING_WBS_ZOOMED_DIALOG],
      ),
    };
  }

  setWelcomeDialog = value => {
    this.setState({ welcomeDialog: value });
  };
  setExampleDialog = value => {
    this.setState({ exampleProjectDialog: value });
  };
  setOnboardingDialog = value => {
    this.setState({ onboardingDialog: value });
  };
  setOnboardingZoomedDialog = value => {
    this.setState({ onboardingZoomedDialog: value });
  };

  onDragEnd = result => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    console.log(
      `item drop details - source ${source.droppableId} ${source.index} - ${destination.droppableId} ${destination.index}`,
    );
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === 'COLUMN') {
      console.log(`  reordering columns`);
      this.props.reorderColumns(source.index, destination.index, false, this.props.zoomDelUID);
    } else {
      console.log(`  reordering cards`);
      this.props.handleCardDrop(
        source,
        destination,
        this.props.zoomDelUID ? 'w' : 'p',
        this.props.viewState,
        false,
      );
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.zoomDelUID !== this.props.zoomDelUID) {
      this.setOnboardingZoomedDialog(
        nextProps.userPrefs.onboarding && nextProps.userPrefs.onboarding[USER.ONBOARDING_WBS_ZOOMED_DIALOG],
      );
    }
    if (nextProps.showDetailsDialog !== this.props.showDetailsDialog) {
      this.setOnboardingDialog(
        nextProps.userPrefs.onboarding && nextProps.userPrefs.onboarding[USER.ONBOARDING_WBS_DIALOG],
      );
      this.setOnboardingZoomedDialog(
        nextProps.userPrefs.onboarding && nextProps.userPrefs.onboarding[USER.ONBOARDING_WBS_ZOOMED_DIALOG],
      );
    }
  }
  render() {
    const {
      classes,
      project,
      withScrollableColumns,
      isCombineEnabled,
      workflows,
      viewState,
      wbsStack,
      sprintData,
      projects,
      firebase,
      user,
      updateViewState,
      saveCurrentProject,
      zoomDelUID,
      showDetailsDialog,
      wbsCards,
      togglePinVisibility,
      toggleDetailPinVisibility,
    } = this.props;

    console.log(`+++ rendering WBS for project ${project.name}`);

    const splitWP = viewState.splitWP;
    const inWorkflow = viewState.currentProjectType === 'Workflow';
    let zoomedCard;
    let pinVisibility = viewState.showUnpinned;
    let changePinVisibility = togglePinVisibility;
    if (zoomDelUID) {
      // zoomedCard = this.props.cards[0];  // temp for testing/dev
      const zoomedCardArray = this.props.cards.filter(card => card.deliverable.uuid === zoomDelUID);
      if (zoomedCardArray.length > 0) zoomedCard = zoomedCardArray[0];

      pinVisibility = viewState.showDetailUnpinned;
      changePinVisibility = toggleDetailPinVisibility;
    }
    utils.clearAllSelections();

    const visibleProjectorWorkflow =
      viewState.currentProjectType !== NAV.TYPE_WORKFLOW ||
      workflows[viewState.currentWorkflowSetId][viewState.currentWorkflowIndex].library.visible;

    const toggleAttachmentsOpen = () => {
      this.setState({ attachmentsOpen: !this.state.attachmentsOpen });
    };
    const badge = project.attachmentLinks?.length;

    let columns = [];
    if (zoomedCard) {
      columns = zoomedCard.wpCards.map((wpCard, index) => {
        let haveOneActiveTask = false;
        wpCard.workpackage.tasks.forEach(task => {
          if (utils.isInProgressStatus(task.status)) haveOneActiveTask = true;
        });
        if (
          (wpCard.status === STATUS.IN_PROGRESS && haveOneActiveTask) ||
          pinVisibility ||
          inWorkflow ||
          wpCard.pinned ||
          wpCard.childPinned
        ) {
          return (
            <DraggableWPColumn
              key={`dc-${wpCard.uuid}`}
              index={index}
              wpCard={wpCard}
              isScrollable={withScrollableColumns}
              isCombineEnabled={isCombineEnabled}
              isExpanded={true}
              viewStack={wbsStack}
            />
          );
        } else {
          return <div style={{ display: 'flex', width: 3, backgroundColor: '#4442', marginRight: 1 }} />;
        }
      });
    } else {
      columns = this.props.cards.map((delCard, index) => {
        let haveOneActiveWP = false;
        delCard.deliverable.workPackages.forEach(wp => {
          if (utils.isInProgressStatus(wp.status)) haveOneActiveWP = true;
        });
        if (
          (delCard.status === STATUS.IN_PROGRESS && haveOneActiveWP) ||
          pinVisibility ||
          inWorkflow ||
          delCard.pinned ||
          delCard.childPinned
        ) {
          return (
            <DraggableDelColumn
              key={`dc-${delCard.uuid}`}
              index={index}
              delCard={delCard}
              isScrollable={withScrollableColumns}
              isCombineEnabled={isCombineEnabled}
              isExpanded={true}
              viewStack={wbsStack}
            />
          );
        } else {
          return <div style={{ display: 'flex', width: 3, backgroundColor: '#4442', marginRight: 1 }} />;
        }
      });
    }
    const stackTop = wbsStack[wbsStack.length - 1];
    let dItemInd = stackTop.item.d;
    let skip = false;
    let curCard = null;
    if (showDetailsDialog) {
      if (dItemInd > wbsCards.delCards.length - 1) {
        dItemInd = wbsCards.delCards.length - 1;
      }
      curCard = wbsCards.delCards[dItemInd];
      let wItemInd = stackTop.item.w;
      if ('wt'.includes(stackTop.level) && wItemInd >= 0) {
        if (wItemInd > curCard.wpCards.length - 1) {
          wItemInd = curCard.wpCards.length - 1;
          skip = true;
        }
        curCard = curCard.wpCards[wItemInd];
      }
      let tItemInd = stackTop.item.t;
      if (stackTop.level === 't' && tItemInd >= 0) {
        if (tItemInd > curCard.taskCards.length - 1) {
          tItemInd = curCard.taskCards.length - 1;
          skip = true;
        }
        curCard = curCard.taskCards[tItemInd];
      }
    }

    return (
      <Fragment>
        <ContentWrapper
          title={
            zoomedCard
              ? `${zoomedCard.deliverable.name}`
              : `${NAV.BOARD}${this.props.isWorkflow ? ' Workflow' : ''}`
          }
          // superTitle={zoomedCard ? 'Deliverable' : undefined}
          // Scope${this.props.isWorkflow ? ' Workflow' : ''}`}
          backToWindow={zoomedCard ? NAV.BOARD : undefined}
          detailEditStr={zoomedCard ? `deliverable.${zoomedCard.index}.Edit` : undefined}
          viewCard={zoomedCard}
          handleMenu={this.props.handleMenu}
          viewStack={wbsStack}
          dispatchUpdateViewState={this.props.dispatchUpdateViewState}
          viewState={this.props.viewState}
          headerMarginTop={10}
          maxTitleCardHeight={'60px'}
          titleChildren={
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: zoomDelUID ? '17px' : '0px',
                }}
              >
                {this.state.attachmentsOpen ? (
                  <Tooltip title={'show WBS'}>
                    <GridOn
                      className={classes.attachIcon}
                      onClick={e => {
                        toggleAttachmentsOpen();
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={!badge || badge === 0 ? 'add attachments' : 'view attachments'}>
                    <Badge badgeContent={badge} color="secondary">
                      <AttachFile
                        className={classes.attachIcon}
                        onClick={e => {
                          toggleAttachmentsOpen();
                        }}
                      />
                    </Badge>
                  </Tooltip>
                )}
              </div>
              {!inWorkflow && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'flex-start',
                    height: '40px',
                    marginLeft: '30px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'flex-start',
                      height: '40px',
                      marginTop: '3px',
                      marginRight: '30px',
                    }}
                  >
                    <Typography variant="h6" style={{ marginRight: '15px', height: '40px' }} noWrap>
                      {`work period: ${viewState.numWeeksInSprint} week${
                        viewState.numWeeksInSprint === 1 ? '' : 's'
                      }`}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'flex-start',
                      height: '40px',
                      marginLeft: '30px',
                    }}
                  >
                    <Tooltip title={'set your team members for this project'}>
                      <Button
                        onClick={() => this.setState({ teamModel: !this.state.teamModel })}
                        color="primary"
                        variant="contained"
                        disabled={false}
                        size="small"
                        className={classes.buttonContainer}
                      >
                        Team
                      </Button>
                    </Tooltip>
                    <Tooltip title={pinVisibility ? 'showing all items' : 'hiding unpinned items'}>
                      <Switch
                        className={classes.switch}
                        checked={pinVisibility}
                        onChange={e => {
                          changePinVisibility();
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          }
        >
          <TeamModal
            // key={new Date().getTime()} // remount each time
            open={this.state.teamModel}
            toggle={() => this.setState({ teamModel: !this.state.teamModel })}
            team={project.sharedWith}
            projects={projects}
            user={user}
            viewState={viewState}
            saveCurrentProject={saveCurrentProject}
            firebase={firebase}
          />
          {this.state.attachmentsOpen ? (
            <AttachmentsTable component={project} />
          ) : (
            <Fragment>
              {visibleProjectorWorkflow ? (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="WBS" type="COLUMN" direction="horizontal" ignoreContainerClipping>
                    {provided => (
                      <div
                        {...provided.droppableProps}
                        className={`${classes.root}`}
                        aria-expanded={true}
                        ref={el => {
                          if (el) {
                            provided.innerRef(el);
                            el.scrollLeft = viewState.scrollTop.wbsLeft ? viewState.scrollTop.wbsLeft : 0;
                            return el;
                          }
                        }}
                        onScroll={e => {
                          if (e.currentTarget.scrollLeft !== viewState.scrollTop.wbsLeft) {
                            if (scrollTimer) {
                              clearTimeout(scrollTimer);
                            }
                            const scrollLeft = e.currentTarget.scrollLeft;
                            scrollTimer = setTimeout(e => {
                              // debounce scoll values
                              viewState.scrollTop.wbsLeft = scrollLeft;
                              // console.log(
                              //   `scroll ${viewState.scrollTop.wbsLeft}`,
                              // );
                              updateViewState(viewState);
                              scrollTimer = undefined;
                            }, 50);
                          }
                        }}
                      >
                        {columns}
                        <div style={{ flex: 0, minWidth: '150px', height: '100px' }} />
                        {/* </div> */}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div
                    style={{
                      position: 'absolute',
                      top: zoomDelUID ? 177 : 157,
                      right: 40,
                      zIndex: 10,
                    }}
                  >
                    <AddButton
                      handleClick={() => {
                        const stackTop = viewState.wbsStack[viewState.wbsStack.length - 1];
                        if (zoomDelUID) {
                          let curDelIndex = stackTop.item.d;
                          if (curDelIndex === undefined || curDelIndex < 0) {
                            curDelIndex = 0;
                          }
                          let curWPIndex = stackTop.item.w;
                          let location = '';
                          if (curWPIndex === undefined || curWPIndex < 0) {
                            curWPIndex = 0;
                            location = '.ABOVE';
                          }
                          this.props.handleMenu(`work package.${curDelIndex}.${curWPIndex}.Add${location}`);
                        } else {
                          let curDelIndex = stackTop.item.d;
                          let location = '';
                          if (curDelIndex === undefined || curDelIndex < 0) {
                            curDelIndex = 0;
                            location = '.ABOVE';
                          }
                          this.props.handleMenu(`deliverable.${curDelIndex}.Add${location}`);
                        }
                      }}
                      size="large"
                      // color={theme.palette.secondary.addButton}
                      color={'#99ccc4'}
                      bgdColor={'#FFF'}
                      // color={'#FFF'}
                      // bgdColor={'#99ccc4'}
                      hoverColor={'#EEE'}
                      borderColor={'#99ccc4'}
                      border={'10px solid #99ccc4'}
                      fontSize={'50px'}
                      fontWeight={700}
                      // borderRadius={'15px'}
                      // borderColor={theme.palette.secondary.addButton}
                      tooltip={zoomDelUID ? 'add a new task group' : 'add a new deliverable'}
                      containerClassName={classes.buttonWrapper}
                    />
                  </div>
                </DragDropContext>
              ) : (
                <h3 style={{ width: 500, margin: 'auto', marginTop: 100 }}>
                  This workflow is invisible. To access the WBS, change the workflow to a visible workflow
                </h3>
              )}
            </Fragment>
          )}
          {this.state.welcomeDialog && !showDetailsDialog && (
            <OnboardingDialog
              title={'Welcome to Project-Assistant'}
              text={[
                'We provided an example project to help you familiarize yourself with Project Assistant.',
                'Click and drag various items to see what you can do.',
                `Here's a quick video tour. "Getting Started"`,
              ]}
              links={[{ text: 'Getting Started', link: USER.VIDEO_URL_STARTUP }]}
              onboardingKey={USER.ONBOARDING_WELCOME_DIALOG}
              closeDialog={this.setWelcomeDialog}
              textOffset={'60px'}
            />
          )}

          {this.state.exampleProjectDialog && !showDetailsDialog && !this.state.welcomeDialog && (
            <OnboardingDialog
              title={'An Example Project'}
              text={[
                'We have created an example project to help you familiarize yourself with Project Assistant.',
                'Try clicking and dragging various items to see what you can do.',
              ]}
              links={null}
              onboardingKey={USER.ONBOARDING_EXAMPLE_PROJECT_DIALOG}
              closeDialog={this.setExampleDialog}
              textOffset={'20px'}
            />
          )}

          {!this.props.zoomDelUID &&
            !showDetailsDialog &&
            this.state.onboardingDialog &&
            !this.state.exampleProjectDialog &&
            !this.state.welcomeDialog && (
              <OnboardingDialog
                title={'The Project'}
                text={[
                  'Use this screen to view and plan your entire project. Projects contain Deliverables, Task Groups, and related Tasks.',
                  'Each column on this screen represents a Deliverable. Related Task Groups are listed under each Deliverable.',
                  'Drag-and-drop to reorganize columns or Task Group cards.',
                  'Double-click a Deliverable (column) to open the Deliverable Screen.',
                ]}
                links={null}
                // {[
                //   { text: 'here', link: 'https://www.youtube.com/watch?v=ebxVHKuNRmI' },
                //   { text: 'here', link: 'https://www.youtube.com/watch?v=xlVpS7cbJN4' },
                // ]}
                topicVideo={{
                  href: USER.VIDEO_URL_PROJECT,
                  text: 'Project',
                }}
                onboardingKey={USER.ONBOARDING_WBS_DIALOG}
                closeDialog={this.setOnboardingDialog}
                textOffset={'50px'}
              />
            )}
          {this.props.zoomDelUID &&
            !showDetailsDialog &&
            this.state.onboardingZoomedDialog &&
            !this.state.exampleProjectDialog &&
            !this.state.welcomeDialog && (
              <OnboardingDialog
                title={'The Deliverable'}
                text={[
                  'Use this screen to dissect and plan a project Deliverable into Task Groups and Tasks.',
                  'Tasks are grouped under each task group (one Task Group per column).',
                  'Drag-and-drop to reorganize columns or Task cards.',
                  'Double-click each column to open a Task Group, or double-click an individual Task for detailed views.',
                ]}
                links={null}
                // {[
                //   { text: 'here', link: 'https://www.youtube.com/watch?v=ebxVHKuNRmI' },
                //   { text: 'here', link: 'https://www.youtube.com/watch?v=xlVpS7cbJN4' },
                // ]}
                topicVideo={{
                  href: USER.VIDEO_URL_DELIVERABLE,
                  text: 'Deliverable',
                }}
                onboardingKey={USER.ONBOARDING_WBS_ZOOMED_DIALOG}
                closeDialog={() => {
                  this.setOnboardingZoomedDialog(false);
                  this.setOnboardingDialog(
                    props.userPrefs.onboarding && props.userPrefs.onboarding[USER.ONBOARDING_WBS_DIALOG],
                  );
                }}
                textOffset={'50px'}
              />
            )}
        </ContentWrapper>
        {showDetailsDialog && !skip && (
          <DetailsSection
            viewCard={curCard}
            viewStack={wbsStack}
            parent={'WBS'}
            project={project}
            community={user.community}
            sprintData={sprintData}
          />
        )}
        {splitWP && !skip && (
          <SplitWPDialog
            parent={'WBS'}
            project={project}
            community={user.community}
            sprintData={sprintData}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(
  state => {
    return {
      viewState: state.viewState,
      workflows: state.workflows,
      projects: state.projects,
      firebase: state.firebase,
      user: state.user,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    reorderColumns,
    handleCardDrop,
    updateViewState,
    togglePinVisibility,
    toggleDetailPinVisibility,
    saveCurrentProject,
    dispatchUpdateViewState,
  },
)(withStyles(styles)(WBS));
