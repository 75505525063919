/* eslint-disable */
import moment from 'moment';

// Action creators for responses to user interface
import * as utils from '../../utils/generalUtilities.js';
import * as ACTION from './actionTypes';
// import * as USER from '../../constants/user';
import * as NAV from '../../constants/navigation';
import * as STATUS from '../../constants/status';
import * as constants from '../../constants/app';

import { signOutUser, updateUsibilityReport, genCommunity } from '../actions/app';
import { setWorkflows } from '../actions/firebase/userTemplates';
import { updateUserPreferencesDoc } from '../actions/firebase/userPreferences';
import { orderProjects } from '../actions/firebase/userProjects';
import { analyticsEvent } from '../actions/analytics';
import group from '../reducers/group.js';
import { SCHEMA_VERSION } from '../projectSchema.js';

/*  NOTICE - these following action creatore are currently not complete or functional and 
    fixing them is the focus of 
    PA-367 (Fix action creators functions to work with redux)

    this code is currently not used in the beta branch so please ignore
    changes in this file until PA-367 is complete 
*/

// processCommand is called from handleMenu to set the command string and adjust viewState
export function processCommand(newCommandString, newViewState) {
  return {
    type: ACTION.PROCESS_COMMAND,
    commandString: newCommandString,
    viewState: newViewState,
  };
}

export function setProjScheduleScrollInfo(scrollInfo) {
  return {
    type: ACTION.SET_PS_SCROLL_TOP,
    scrollInfo: scrollInfo,
  };
}

export function addNewAttachment(newViewState) {
  return {
    type: ACTION.ADD_ATTACHMENT,
    viewState: newViewState,
  };
}

export function editExistingAttachment(newViewState) {
  return {
    type: ACTION.EDIT_ATTACHMENT,
    viewState: newViewState,
  };
}

export function deleteCurrentAttachment(newViewState) {
  return {
    type: ACTION.DELETE_ATTACHMENT,
    viewState: newViewState,
  };
}

export function setSprintPeriod(numWeeksInSprint) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const { viewState, firebase, user } = reduxStore;

    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    console.log(`========= in setSprintPeriod: setting project numWeeksInSprint to ${numWeeksInSprint}`);

    curProject.numWeeksInSprint = numWeeksInSprint;
    const newViewState = {
      ...viewState,
      numWeeksInSprint: numWeeksInSprint,
    };
    saveProjectData(curProject, newViewState, dispatch, firebase, user);
  };
}

export function dispatchUpdateProjects(projects, newViewState = null, clearDialog = false) {
  // once only allows the value to be set once per session. fiorst time
  return (dispatch, getState) => {
    const { user } = getState();
    dispatch(updateProjects(projects, newViewState, user.email, clearDialog));
  };
}

// updateProjects is called to update the projects store
export function updateProjects(projects, newViewState = null, userEmail, clearDialog = false) {
  console.log(`in updateProjects with ${projects.length} projects`);
  if (clearDialog && newViewState) {
    Object.assign(newViewState, {
      alert: false,
      alertYesButton: 'yes',
      form: false,
      formType: '',
      help: false,
      textLabel: '',
      title: '',
      text: '',
    });
  }
  console.log(`+++++++++++++++++++ running getAssignments from updateProjects +++++++++++++++`);

  return {
    type: ACTION.UPDATE_PROJECTS,
    projects: projects.slice(), // create copy of array for immutable detection logic
    viewState: newViewState,
    community: genCommunity(userEmail, projects), // update the community on all changes
    // assignments: utils.getAssignments(projects, { [userEmail]: true }),
  };
}

// updateProjects is called to update the library store
export function setInfoDialog(infoDialogData) {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.SET_INFO_DIALOG,
      infoDialogData: infoDialogData,
    });
  };
}

// updateProjects is called to update the library store
export function updateWorkflows(workflows, newViewState = null, clearDialog = false) {
  return (dispatch, getState) => {
    if (clearDialog && newViewState) {
      Object.assign(newViewState, {
        alert: false,
        alertYesButton: 'yes',
        form: false,
        formType: '',
        help: false,
        textLabel: '',
        title: '',
        text: '',
      });
    }
    // following is a hack to pass around a lookup table
    window.templateUUIDLookup = utils.genTemplateComponentLookupTable(workflows);
    console.log(`in updateWorkflows`);
    dispatch({
      type: ACTION.UPDATE_WORKFLOWS,
      workflows: workflows,
      newViewState: newViewState,
    });
  };
}

// updateViewState is called to update the viewState store
export function updateViewState(newViewState, clearDialog = false) {
  if (clearDialog) {
    Object.assign(newViewState, {
      alert: false,
      alertYesButton: 'yes',
      form: false,
      formType: '',
      help: false,
      textLabel: '',
      title: '',
      text: '',
    });
  }
  return {
    type: ACTION.SET_VIEW_STATE,
    viewState: newViewState,
  };
}
export function dispatchUpdateViewState(viewState) {
  return (dispatch, getState) => {
    const res = updateViewState(viewState);
    dispatch(res);
  };
}

// export function checkValidGroup(newGroup, type) {
//   return new Promise((resolve, reject) => {
//     if(type === 'id') {
//       // search firebase for existing group
//       firebase.db
//       .collection("groups")
//       .doc(newGroup)
//       .then(docRef => {
//         newGroup.id = docRef.id;
//         dispatch({
//           type: ACTION.SET_GROUP,
//           group: newGroup,
//         });
//       })
//       .catch(function(error) {
//         console.log(`Error storing group: ${error}`);
//       });
//     } else { // name
//     }
//   })
// }

function createNewGroupsTemplates(firebase, dispatch, templates, userEmail, setId) {
  const newTemplates = [];
  templates[constants.CORE_SMART_TEMPLATES_ID].forEach(template => {
    const newTemplate = utils.copyProject(userEmail, template, true);
    newTemplate.workflowSetId = setId;
    newTemplates.push(newTemplate);
  });
  const fbBatch = firebase.db.batch();
  newTemplates.forEach(template => {
    const docRef = firebase.db.collection('workflows').doc(); //automatically generate unique id
    template.id = docRef.id;
    fbBatch.set(docRef, template);
  });
  fbBatch
    .commit()
    .then(result => {
      templates[setId] = newTemplates;
      dispatch(setWorkflows(templates));
    }) // workflows store update now done in snapshot callback - see App.js componentDidUpdate
    .catch(function(error) {
      console.log(`Error adding smart templates to backend: ${error}`);
    });

  return newTemplates;
}

export function setGroup(dispatch, getState, newGroup, type, firebase, viewState = null) {
  let { user, group, workflows } = getState();

  let newUserGroups = [];
  switch (type) {
    case 'id':
      // look up existing group doc
      firebase.db
        .collection('groups')
        .doc(newGroup.id)
        .get()
        .then(docRef => {
          if (docRef.exists) {
            const existingGroupDoc = { ...docRef.data(), id: docRef.id };
            if (!existingGroupDoc.members.find(email => email === newGroup.members[0])) {
              // if new admin not part of group, add them
              existingGroupDoc.members.push(newGroup.members[0]);
            }
            // update user info/permissions/tags
            existingGroupDoc.info[newGroup.members[0]] = newGroup.info[newGroup.members[0]];

            const filteredMembersList = [];
            let curInfo;
            existingGroupDoc.members.forEach(mEmail => {
              // update user groups
              curInfo = existingGroupDoc.info[mEmail];
              if (curInfo.isAdmin) filteredMembersList.push(mEmail);
            });
            existingGroupDoc.members.forEach(mEmail => {
              // update user groups
              curInfo = existingGroupDoc.info[mEmail];
              if (!curInfo.isAdmin) filteredMembersList.push(mEmail);
            });

            // update user docs to include updated group
            newUserGroups = [];
            user.groups.forEach(group => {
              // update user groups
              newUserGroups.push(group.id === existingGroupDoc.id ? existingGroupDoc : group);
            });
            if (!newUserGroups.find(group => group.id === existingGroupDoc.id)) {
              newUserGroups.push(existingGroupDoc);
            }

            existingGroupDoc.members = filteredMembersList;
            // update groups with new admin info
            firebase.db
              .collection('groups')
              .doc(existingGroupDoc.id)
              .set(existingGroupDoc)
              .then(docRef => {
                dispatch({
                  type: ACTION.SET_GROUP,
                  group: existingGroupDoc,
                  viewState: viewState,
                  userGroups: newUserGroups,
                  workflows: null,
                });
              })
              .catch(function(error) {
                alert(`Error updating group: ${error}`);
                console.log(`Error updating group: ${error}`);
              });
          } else {
            alert(`Could not find group id: ${newGroup.id}. Please check your source and try again`);
          }
        })
        .catch(function(error) {
          alert(`Could not find group id: ${error}`);
          console.log(`Could not find group id: ${error}`);
        });
      break;
    case 'name':
      newUserGroups = user.groups.slice();
      newUserGroups.push(newGroup);

      const docRef = firebase.db.collection('groups').doc(); //automatically generate unique id
      newGroup.id = docRef.id;
      firebase.db
        .collection('groups')
        .doc(docRef.id)
        .set(newGroup)
        .then(docRef => {
          const newTemplates = Object.assign({}, workflows);
          if (!group) {
            // new group
            newTemplates[newGroup.id] = createNewGroupsTemplates(
              firebase,
              dispatch,
              workflows,
              user.email,
              newGroup.id,
            );
          }
          dispatch({
            type: ACTION.SET_GROUP,
            group: newGroup,
            viewState: viewState,
            userGroups: newUserGroups,
            workflows: !group ? newTemplates : null,
          });
        })
        .catch(function(error) {
          alert(`Error creating group: ${error}`);
          console.log(`Error creating group: ${error}`);
        });
      break;
    case 'update':
      user.groups.forEach(group => {
        // update user groups
        newUserGroups.push(group.id === newGroup.id ? newGroup : group);
      });
      firebase.db
        .collection('groups')
        .doc(newGroup.id)
        .set(newGroup)
        .then(docRef => {
          dispatch({
            type: ACTION.SET_GROUP,
            group: newGroup,
            viewState: viewState,
            userGroups: newUserGroups,
            workflows: null,
          });
        })
        .catch(function(error) {
          alert(`Error updating group: ${error}`);
          console.log(`Error updating group: ${error}`);
        });
      break;
  }
}
export function dispatchSetGroup(newGroup, type, firebase, viewState = null) {
  return (dispatch, getState) => {
    setGroup(dispatch, getState, newGroup, type, firebase, viewState);
  };
}

// resetUIParms is called to reset the viewState store when user selects no/cancel in dialogs
export function resetUIParms() {
  return {
    type: ACTION.RESET_UI_PARMS,
  };
}
export function toggleNotNeededTaskVisibility() {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.TOGGLE_NOT_NEEDED_TASK_VISIBILITY,
    });
  };
}
export function resetProjectSchedule() {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.RESET_PROJECT_SCHEDULE_BUTTONS,
    });
  };
}
export function toggleSortTasks() {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.TOGGLE_SORT_TASKS,
    });
  };
}
// generic helper function to find id in list

const saveQ = {};

export function saveProjectToBackend(firebase, changedProject, projSaveQ) {
  // console.log(`firebase.db.set project - saveProjectData`);

  firebase.db
    .collection(changedProject.firebaseCollection)
    .doc(changedProject.id)
    .set(changedProject)
    .then(result => {
      console.log(`done ==================`);
      // project store update now done in snapshot callback - see App.js componentDidUpdate
    })
    .catch(error => {
      console.warn(`======= Error writing project ${changedProject.id}: ${error}`);
      alert(
        `Unable to save project ${changedProject.name}: ${error}. Please logout and back in to reset it's data.`,
      );
    });
}

// generic helper to save current project to firestore then (via firebase doc listener - see App.js:componentDidUpdate()) the local store will be updated on success firebase update
function saveProjectData(changedProject, newViewState, dispatch, firebase, user) {
  dispatch(updateViewState(newViewState));
  changedProject.lastModifiedBy = user.email;
  const blockSave = false; // change to true to aviod updating server dbase during during dev that could corrupt the dbase
  // console.log(`changed advice - 2 SPD - ${changedProject.deliverables[0].workPackages[0].advice?.blocks[0].text}`)
  // console.log(`changed advice - 2 SPD - ${changedProject.deliverables[0].workPackages[0].task[0]?.advice.blocks[0].text}`)

  const projSaveQ = saveQ[changedProject.id] ?? { new: true, lastSaveTS: new Date().getTime() - 10000 }; // in the distance past
  if (!saveQ[changedProject.id]) console.warn(`new Q generated for project ${changedProject.name}`);
  saveQ[changedProject.id] = projSaveQ;
  projSaveQ.content = { ...changedProject };
  clearTimeout(projSaveQ.timer);

  projSaveQ.timer = null;
  const testTime = moment().subtract(2, 'seconds');
  const lastSaveTime = moment(projSaveQ.lastSaveTS);
  if (lastSaveTime.isBefore(testTime)) {
    // safe to FireStore
    console.log(`+++++++ direct - ${projSaveQ.lastSaveTS} ${projSaveQ.new}`);
    projSaveQ.lastSaveTS = new Date().getTime();
    delete projSaveQ.new;
    saveProjectToBackend(firebase, projSaveQ.content, projSaveQ);
  } else {
    projSaveQ.timer = setTimeout(() => {
      console.log(`======= delayed write -  ${projSaveQ.lastSaveTS} ${projSaveQ.new}`);
      saveProjectToBackend(firebase, projSaveQ.content, projSaveQ);
      projSaveQ.timer = null;
    }, 2000);
  }
  dispatch({ type: ACTION.UPDATE_PROJECT_CARDS });
}

export function batchSaveProjects(projects, getState) {
  const { firebase } = getState();

  const fbBatch = firebase.db.batch();
  projects.forEach(project => {
    console.log(`===============  updating project ${project.name} to schema ${SCHEMA_VERSION}`);
    const docRef = firebase.db.collection('projects').doc(project.id);
    fbBatch.set(docRef, project);
  });
  fbBatch.commit().catch(function(error) {
    console.log(`Error updating projects on backend: ${error}`);
  });
}

export function batchSaveWorkflows(workflows, getState) {
  const { firebase } = getState();

  const fbBatch = firebase.db.batch();
  workflows.forEach(wf => {
    console.log(`===============  updating workflow ${wf.name} to schema ${SCHEMA_VERSION}`);

    const docRef = firebase.db.collection('workflows').doc(wf.id);
    fbBatch.set(docRef, wf);
  });
  fbBatch.commit().catch(function(error) {
    console.log(`Error updating library on backend: ${error}`);
  });
}

export function saveWorkflows(newWorkflows) {
  return (dispatch, getState) => {
    saveWorkflowsCore(newWorkflows, getState);
  };
}

export function saveWorkflowsCore(newWorkflows, getState) {
  const { firebase } = getState();

  const fbBatch = firebase.db.batch();
  Object.keys(newWorkflows).forEach(setId => {
    newWorkflows[setId].forEach(wf => {
      const docRef = firebase.db.collection('workflows').doc(wf.id);
      fbBatch.set(docRef, wf);
    });
  });
  fbBatch.commit().catch(function(error) {
    console.log(`Error updating library on backend: ${error}`);
  });
}

// a simple thunk to save the current project in firestore and store
export function saveCurrentProject(curProject = null) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const { user, firebase } = reduxStore;
    // curProject = curProject ? curProject : utils.getCurrentProjectOrWorkflow(reduxStore);
    curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    // console.log(`changed advice - 1 SCP - ${curProject.deliverables[0].workPackages[0].advice?.blocks[0].text}`)
    // console.log(`changed advice - 1 SCP - ${curProject.deliverables[0].workPackages[0].tasks[0]?.advice.blocks[0].text}`)
    saveProjectData(curProject, null, dispatch, firebase, user);
  };
}
/**
 * Triggers the date recalculation for the project
 */
export function rollUpDates(resetWPDates = { start: false, end: false, taskID: null }, project = null) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const { user, firebase } = reduxStore;
    const curProject = project ? project : utils.getCurrentProjectOrWorkflow(reduxStore);
    utils.refreshStats(curProject, resetWPDates);
    saveProjectData(curProject, null, dispatch, firebase, user);
  };
}

export function mergeWorkPackage(wpCard1, wpCard2) {
  return (dispatch, getState) => {
    // merge wp2 tasks into wp1
    // remove wp2 from parent
    // save project and rerender

    // set viewState.mergeWPs = null

    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    utils.refreshStats(curProject);
    saveProjectData(curProject, null, dispatch, firebase, user);
  };
}
export function dispatchSplitWorkPackage(parentDel, workpackage) {
  return (dispatch, getState) => {
    dispatch(splitWorkPackage(parentDel, workpackage));
  };
}
export function splitWorkPackage(parentDel, workpackage) {
  const list1 = [];
  const list2 = [];
  const doneTask = workpackage.tasks.find(task => {
    return task.status === STATUS.DONE;
  });
  let list1Check;
  if (doneTask) {
    list1Check = task => task.status === STATUS.DONE;
  } else {
    list1Check = task => task.status !== STATUS.NOT_STARTED;
  }

  workpackage.tasks.forEach(task => {
    if (task.status !== STATUS.NOT_NEEDED) {
      if (list1Check(task)) {
        list1.push({ ...task });
      } else {
        list2.push({ ...task });
      }
    }
  });

  return {
    type: ACTION.SPLIT_WP,
    splitWP: {
      deliverable: parentDel,
      workpackage: workpackage,
      taskLists: [list1, list2],
      newWPName: `${workpackage.name}`,
    },
  };
}
export function finishSplitWorkPackage() {
  return (dispatch, getState) => {
    const reduxStore = getState();
    let { viewState, firebase, user } = reduxStore;
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);

    const workpackage = viewState.splitWP.workpackage;
    const parentDel = viewState.splitWP.deliverable;
    const newWPName = viewState.splitWP.newWPName;

    const curDel = curProject.deliverables.find(del => {
      return del.uuid === parentDel.uuid;
    });
    if (!curDel) {
      console.error('could not find parent deliverable during WP split');
      dispatch({
        type: ACTION.SPLIT_WP,
        splitWP: null,
        updateTS: true,
      });
      return;
    }
    const splitWPInd = curDel.workPackages.findIndex(wp => {
      return wp.uuid === workpackage.uuid;
    });
    if (splitWPInd < 0) {
      console.error('could not find workpackage during WP split');
      dispatch({
        type: ACTION.SPLIT_WP,
        splitWP: null,
      });
      return;
    }
    // split WP into 2...

    const newWP = {
      ...curDel.workPackages[splitWPInd],
      uuid: utils.generateNewUID(),
      refId: utils.genWPRefId(curProject),
      name: newWPName,
    };
    curDel.workPackages[splitWPInd].tasks = viewState.splitWP.taskLists[0];
    newWP.tasks = viewState.splitWP.taskLists[1];
    curDel.workPackages.splice(splitWPInd + 1, 0, newWP);
    viewState = { ...viewState, splitWP: null };

    let wpNotStarted = curDel.workPackages[splitWPInd].tasks.reduce((acc, task) => {
      return acc && task.status === STATUS.NOT_STARTED;
    }, true);
    if (wpNotStarted) curDel.workPackages[splitWPInd].pinned = true;
    wpNotStarted = curDel.workPackages[splitWPInd + 1].tasks.reduce((acc, task) => {
      return acc && task.status === STATUS.NOT_STARTED;
    }, true);
    if (wpNotStarted) curDel.workPackages[splitWPInd + 1].pinned = true;

    utils.refreshStats(curProject);

    saveProjectData(curProject, viewState, dispatch, firebase, user);
  };
}
export function clearSplitWorkPackage() {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION.SPLIT_WP,
      splitWP: null,
    });
  };
}
export function updateSplitWorkPackage(list1, list2) {
  return (dispatch, getState) => {
    const { viewState } = getState();
    dispatch({
      type: ACTION.SPLIT_WP,
      splitWP: {
        ...viewState.splitWP,
        taskLists: [list1, list2],
      },
    });
  };
}
export function setSplitWorkPackageName(newWPName) {
  return (dispatch, getState) => {
    const { viewState } = getState();
    dispatch({
      type: ACTION.SPLIT_WP,
      splitWP: {
        ...viewState.splitWP,
        newWPName: newWPName,
      },
    });
  };
}
export function tellUserDocChanged(viewState, updatedDoc) {
  return (dispatch, getState) => {
    if (updatedDoc && !updatedDoc.lastModifiedBy) {
      // a warning to track down new projects etc
      console.warn(
        `user project $[updatedDoc.name] does not have the lastModifiedBy field set. setting it to creator `,
      );
      updatedDoc.lastModifiedBy = updatedDoc.creator;
    }
    const modBy = updatedDoc && updatedDoc.lastModifiedBy ? updatedDoc.lastModifiedBy : 'not set';
    Object.assign(viewState, {
      updatedDoc: updatedDoc,
      alert: true,
      alertYesButton: 'ok',
      title: `Project Changed.`,
      textLabel: 'Project',
      text: `${modBy} has changed this projects since the last time you modified it.`,
    });
    dispatch(processCommand(`project.${viewState.currentProjectIndex}.NEW_DATA`, viewState));
  };
}

export function setUserColleguePref(data) {
  return (dispatch, getState) => {
    const { userPrefs, firebase, user } = getState();

    const newUserPrefs = Object.assign({}, userPrefs); // copy required for redux persistence
    newUserPrefs.collegues[data.email] = { color: data.color };
    updateUserPreferencesDoc(firebase, user.uid, newUserPrefs).catch(error => {
      console.warn(`not able to save updated collegue colors to user preferences in FireStore`);
    });
    dispatch({
      type: ACTION.SET_PREFERENCES,
      userPrefs: newUserPrefs,
    });
  };
}

// post drop, reordering of columns
export function reorderColumns(srcIndex, destIndex, isLibraryChange, zoomDelUID) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    const { user, firebase } = reduxStore;
    let curDeliverables = curProject.deliverables;
    if (zoomDelUID) {
      let zoomedDeliverable;
      // zoomedDeliverableCard = this.props.cards[0];  // temp for testing/dev
      const zoomedDeliverableArray = curDeliverables.filter(del => del.uuid === zoomDelUID);
      if (zoomedDeliverableArray.length > 0) zoomedDeliverable = zoomedDeliverableArray[0];
      if (!zoomedDeliverable) {
        console.error(`referencing bad zoom deliverable in drop`);
        return;
      }
      const [movedObj] = zoomedDeliverable.workPackages.splice(srcIndex, 1);
      zoomedDeliverable.workPackages.splice(destIndex, 0, movedObj);
    } else {
      if (isLibraryChange) {
        if (srcIndex < 2 || destIndex < 2) return;
        curDeliverables = curProject.library.components.deliverables;
        srcIndex -= 2;
        destIndex -= 2;
      }
      const [movedObj] = curDeliverables.splice(srcIndex, 1);
      curDeliverables.splice(destIndex, 0, movedObj);
    }
    saveProjectData(curProject, null, dispatch, firebase, user);
  };
}

// post drop, move of workpackages
export function handleCardDrop(source, destination, level = 'p', viewState, isLibraryChange) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    const { user, firebase } = reduxStore;
    let curStack = viewState.wbsStack;
    if (isLibraryChange) {
      curStack = viewState.libStack;
    }
    let curDeliverables = curProject.deliverables;
    const curStackTop = curStack[curStack.length - 1];
    let curWorkPackages;
    let curTasks;
    let zoomedDeliverable;

    if (isLibraryChange) {
      curDeliverables = curProject.library.components.deliverables;
      curWorkPackages = curProject.library.components.workPackages;
      curTasks = curProject.library.components.tasks;
    } else {
      if (curStackTop.zoomDelUID) {
        // zoomedDeliverableCard = this.props.cards[0];  // temp for testing/dev
        const zoomedDeliverableArray = curDeliverables.filter(del => del.uuid === curStackTop.zoomDelUID);
        if (zoomedDeliverableArray.length > 0) zoomedDeliverable = zoomedDeliverableArray[0];
        if (!zoomedDeliverable) {
          console.error(`referencing bad zoom deliverable in card drop`);
          return;
        }
        curWorkPackages = zoomedDeliverable.workpackages;
        // const [movedObj] = zoomedDeliverable.workPackages.splice(srcIndex, 1);
        // zoomedDeliverable.workPackages.splice(destIndex, 0, movedObj);
      } else {
        curWorkPackages =
          curDeliverables && curDeliverables[curStack.d] ? curDeliverables[curStack.d].workpackages : null;
      }
      curTasks = curWorkPackages && curWorkPackages[curStack.w] ? curWorkPackages[curStack.w].tasks : null;
    }

    let destIndex;
    let sourceWorkPackages;
    let destWorkPackages;
    if ('pd'.includes(level)) {
      // workpackages dropped in del wp list
      if (
        (source.droppableId !== NAV.LIBRARY_TASK_COL_UUID &&
          destination.droppableId === NAV.LIBRARY_TASK_COL_UUID) ||
        (source.droppableId === NAV.LIBRARY_TASK_COL_UUID &&
          destination.droppableId !== NAV.LIBRARY_TASK_COL_UUID)
      )
        return;

      if (source.droppableId === NAV.LIBRARY_TASK_COL_UUID) {
        const [movedObj] = curTasks.splice(source.index, 1);
        curTasks.splice(destination.index, 0, movedObj);
        curStackTop.item.d = 0;
        curStackTop.item.w = 0;
        curStackTop.item.t = destination.index;
      } else {
        let sourceDeliverable;
        if (source.droppableId === NAV.LIBRARY_WP_COL_UUID) {
          sourceWorkPackages = curProject.library.components.workPackages;
        } else {
          sourceDeliverable = utils.findObjectWithId(curDeliverables, source.droppableId);
          sourceWorkPackages = sourceDeliverable.workPackages;
        }

        let destDeliverable;
        if (destination.droppableId === NAV.LIBRARY_WP_COL_UUID) {
          destWorkPackages = curProject.library.components.workPackages;
          destIndex = 1;
        } else {
          if (curStackTop.zoomDelUID) {
            destDeliverable = zoomedDeliverable;
          } else {
            destDeliverable = utils.findObjectWithId(curDeliverables, destination.droppableId);
            destIndex = utils.findObjectIndexWithId(curDeliverables, destination.droppableId);
            destIndex += isLibraryChange ? 2 : 0;
          }
          destWorkPackages = destDeliverable.workPackages;
        }
        let [movedObj] = sourceWorkPackages.splice(source.index, 1);
        destWorkPackages.splice(destination.index, 0, movedObj);
        utils.refreshStats(curProject);
        curStackTop.item.d = destIndex;
        curStackTop.item.w = destination.index;
      }
    } else {
      // tasks dropped in wp task list
      let sourceWorkPackage;
      let destWorkPackage;

      if (!isLibraryChange || curStackTop.d >= 2) {
        if (zoomedDeliverable) {
          zoomedDeliverable.workPackages.forEach((wp, index) => {
            if (wp.uuid === source.droppableId) {
              sourceWorkPackage = wp;
            }
            if (wp.uuid === destination.droppableId) {
              destWorkPackage = wp;
              destIndex = index;
            }
          });
        } else {
          curDeliverables.forEach(del => {
            del.workPackages.forEach((wp, index) => {
              if (wp.uuid === source.droppableId) {
                sourceWorkPackage = wp;
              }
              if (wp.uuid === destination.droppableId) {
                destWorkPackage = wp;
                destIndex = index;
              }
            });
          });
        }
      } else {
        // WP col in library
        curWorkPackages.forEach((wp, index) => {
          if (wp.uuid === source.droppableId) {
            sourceWorkPackage = wp;
          }
          if (wp.uuid === destination.droppableId) {
            destWorkPackage = wp;
            destIndex = index;
          }
        });
      }
      let [movedObj] = sourceWorkPackage.tasks.splice(source.index, 1);
      destWorkPackage.tasks.splice(destination.index, 0, movedObj);
      utils.refreshStats(curProject);
      curStackTop.item.w = destIndex;
      curStackTop.item.t = destination.index;
    }

    saveProjectData(curProject, viewState, dispatch, firebase, user);
  };
}

// handle task change
export function handleTaskChange(e) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    const { user, firebase, viewState } = reduxStore;

    e.preventDefault();
    e.stopPropagation();

    const values = e.target.value.split('.');
    // const taskText = values[0];
    const taskString = values.length === 4 ? values[2] : values[1];
    const answer = values.length === 4 ? values[3] : values[2];

    const deliverableIndex = viewState.currentDeliverable;
    const workPackageIndex = viewState.currentWorkPackage;
    const taskNumber = parseInt(taskString, 10);

    let curDeliverable = curProject.deliverables[deliverableIndex];
    let curWorkPackage = curDeliverable.workPackages[workPackageIndex];
    let curTask = curWorkPackage.tasks[taskNumber];

    console.log(`handleTaskChange(${e.target.value},${workPackageIndex},${deliverableIndex},${taskNumber})`);
    // make sure indices are within bounds
    if (
      deliverableIndex >= 0 &&
      deliverableIndex < curProject.deliverables.length &&
      workPackageIndex >= 0 &&
      workPackageIndex < curDeliverable.workPackages.length &&
      taskNumber >= 0 &&
      taskNumber < curWorkPackage.tasks.length
    ) {
      setTaskAnswer(curProject, curTask, answer, dispatch, firebase, user);
    }
  };
}

function setTaskAnswer(curProject, curTask, answer, dispatch, firebase, user) {
  curTask.answer = answer;
  utils.refreshStats(curProject);
  saveProjectData(curProject, null, dispatch, firebase, user);
}

export const addAttachment = (fileInfo, component) => {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    const { user, firebase } = reduxStore;

    console.log(`Adding attachment ${fileInfo.name}`);
    if (component.attachmentLinks) {
      component.attachmentLinks.push(fileInfo);
    } else {
      component.attachmentLinks = [fileInfo];
    }
    saveProjectData(curProject, null, dispatch, firebase, user);
  };
};

export const deleteAttachment = (fileInfo, component) => {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    const { user, firebase } = reduxStore;
    console.log(`Deleting attachment ${fileInfo.name}`);
    if (component.attachmentLinks) {
      let attachmentIndex = component.attachmentLinks.findIndex(
        attachment => attachment.uuid === fileInfo.uuid,
      );
      if (attachmentIndex >= 0) {
        component.attachmentLinks.splice(attachmentIndex, 1);
      }
    }
    saveProjectData(curProject, null, dispatch, firebase, user);
  };
};

// handle project info change
export function handleProjectInfoChange(changedProjectInfo) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    const { user, firebase, projects, viewState } = reduxStore;

    const hasNameChanged = curProject.name !== changedProjectInfo.name;
    Object.assign(curProject, changedProjectInfo);

    if (hasNameChanged) {
      // sort projects - the sort order is projects vs templates
      // project name alphabetic
      orderProjects(projects);

      // projects.sort((p1, p2) =>
      //   p1.template === p2.template
      //     ? p1.name.toLowerCase < p2.name.toLowerCase
      //       ? -1
      //       : p1.name.toLowerCase === p2.name.toLowerCase
      //       ? 0
      //       : 1
      //     : p1.template
      //     ? 1
      //     : -1,
      // );
    }

    viewState.currentProjectIndex = projects.findIndex(project => project.id === curProject.id);
    if (viewState.currentProjectIndex < 0 || viewState.currentProjectIndex >= projects.length) {
      viewState.currentProjectIndex = 0;
    }
    // save reordered projects
    dispatch(updateProjects(projects, viewState));

    // now save data to firebase
    saveProjectData(curProject, viewState, dispatch, firebase, user);
  };
}

export function handleWorkPackageChange(newDeliverableIndex, newWorkPackageIndex) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    let { viewState } = getState();

    console.log(`handleWorkPackageChange(${newDeliverableIndex}, ${newWorkPackageIndex})`);
    let curDeliverable = curProject.deliverables[viewState.currentDeliverable];

    if (newDeliverableIndex < 0 || newDeliverableIndex >= curProject.deliverables.length) {
      newDeliverableIndex = 0;
    }
    if (newWorkPackageIndex < 0 || newWorkPackageIndex >= curDeliverable.workPackages.length) {
      newWorkPackageIndex = 0;
    }
    viewState.currentDeliverable = newDeliverableIndex;
    viewState.currentWorkPackage = newWorkPackageIndex;

    dispatch(updateViewState(viewState));
  };
}

export function handleYes(response) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    let { firebase, projects, workflows, templates, viewState, user, commandString } = reduxStore;

    let newText = response.newText;
    console.log(`handleYes(e=${newText},commandString=${commandString}`);

    let currentProjIndex =
      viewState.currentProjectType === NAV.TYPE_PROJECT
        ? viewState.currentProjectIndex
        : viewState.currentWorkflowIndex;
    let currentDeliverableIndex = viewState.currentDeliverable;
    let currentWorkPackageIndex = viewState.currentWorkPackage;
    let currentTaskIndex = viewState.currentTask;

    let action = utils.parseCommand(commandString);
    let curDeliverable;
    let curWorkPackage;
    let curStack = viewState.wbsStack;
    const isLibraryChange = viewState.curWindow === NAV.LIBRARY_BOARD;
    const isWorkflow = viewState.currentProjectType === 'Workflow';
    try {
      if (isLibraryChange) {
        curStack = viewState.libStack;
        if (viewState.currentDeliverable > 1) {
          curDeliverable = curProject.library.components.deliverables[viewState.currentDeliverable - 2];
        } else {
          curDeliverable = null;
        }
        if (!curDeliverable) {
          if (viewState.currentDeliverable === 0) {
            curWorkPackage = null;
          } else {
            // (viewState.currentDeliverable === 1)
            curWorkPackage = curProject.library.components.workPackages[viewState.currentWorkPackage];
          }
        } else {
          curWorkPackage = curDeliverable.workPackages[viewState.currentWorkPackage];
        }
        // if (viewState.currentDeliverable === 0 || !curDeliverable) {
        //   curWorkPackage = null;
        // } else if (viewState.currentDeliverable === 1) {
        //   curWorkPackage =
        //     curProject.library.components.workPackages[
        //       viewState.currentWorkPackage
        //     ];
        // } else {
        //   curWorkPackage =
        //     curDeliverable.workPackages[viewState.currentWorkPackage];
        // }
      } else {
        curDeliverable = curProject.deliverables[viewState.currentDeliverable];
        curWorkPackage = curDeliverable.workPackages[viewState.currentWorkPackage];
      }
    } catch (error) {
      if (!(action.target === 'PROJECT' && action.verb === 'DELETE')) {
        throw new Error('missing deliverables or workpackages in handleYes');
      }
    }

    commandString = 'needs reconnect';

    if (action.target === 'TASK') {
      let newWBSStack = viewState.wbsStack;
      let newLibStack = viewState.libStack;
      let curTasks;
      if (isLibraryChange) {
        if (viewState.currentDeliverable === 0) {
          // cur pointing to Tasks col in library
          curTasks = curProject.library.components.tasks;
        } else {
          curTasks = curWorkPackage.tasks;
        }
      } else {
        curTasks = curWorkPackage.tasks;
      }
      switch (action.verb) {
        case 'ADD':
          let newTask = utils.createNewTask(newText, curProject, response.template, isWorkflow, true);

          action.index = action.index + (action.location === 'ABOVE' ? 0 : 1);
          if (isLibraryChange) {
            newTask.refId = null;
            newLibStack.push({
              screen: NAV.BOARD_DETAIL,
              item: { d: viewState.currentDeliverable, w: viewState.currentWorkPackage, t: action.index },
              level: 't',
            });
          } else {
            const stackTop = newWBSStack[newWBSStack.length - 1];
            newWBSStack.push({
              screen: NAV.BOARD_DETAIL,
              item: { d: stackTop.item.d, w: stackTop.item.w, t: action.index },
              level: 't',
            });
          }
          curTasks.splice(action.index, 0, newTask);
          currentTaskIndex = action.index;
          utils.refreshStats(curProject);

          dispatch(
            analyticsEvent(getState, 'Pro.Del.WP.Q.add', {
              project: curProject.name,
              deliverable: curDeliverable ? curDeliverable.name : 'wp_library',
              workPackage: curWorkPackage ? curWorkPackage.name : 'task_library',
              task: newText,
            }),
          );
          break;
        case 'EDIT':
          curTasks[action.index].name = newText;
          break;
        case 'DELETE':
          dispatch(
            analyticsEvent(getState, 'Pro.Del.WP.Q.delete', {
              project: curProject.name,
              deliverable: curDeliverable ? curDeliverable.name : 'wp_library',
              workPackage: curWorkPackage ? curWorkPackage.name : 'task_library',
              task: curTasks[action.index].name,
            }),
          );

          // are cur WP est dates based on this task?  - ie were the WP est dates set by this item
          const taskToDelete = curTasks[action.index];
          curTasks.splice(action.index, 1);
          currentTaskIndex = action.index - 1;
          if (currentTaskIndex >= curTasks.length) {
            currentTaskIndex = curTasks.length - 1;
          }
          if (curTasks.length === 0) {
            curTasks.push(utils.createNewTask('New Task', curProject, undefined, isWorkflow, false));
          }

          const resetWPEstStart =
            utils.subcomponetsHaveNoDateSet(curWorkPackage, 'start', 'W') &&
            taskToDelete?.expectedStartDate !== '';
          const resetWPEstEnd =
            utils.subcomponetsHaveNoDateSet(curWorkPackage, 'end', 'W') &&
            taskToDelete?.expectedEndDate !== '';
          utils.refreshStats(curProject);

          const stackTop = curStack[curStack.length - 1];
          stackTop.item.t -= 1;
          break;
        default:
      }

      Object.assign(viewState, {
        currentTask: currentTaskIndex,
        wbsCardsUpdateTS: new Date().getTime(),
        libCardsUpdateTS: new Date().getTime(),
        prevWindow: isLibraryChange ? viewState.curWindow : viewState.prevWindow,
        wbsStack: newWBSStack,
        libStack: newLibStack,
        form: null,
        alert: null,
      });

      saveProjectData(curProject, viewState, dispatch, firebase, user);
    }
    if (action.target === 'WORK PACKAGE') {
      let newWBSStack = viewState.wbsStack;
      let newLibStack = viewState.libStack;
      switch (action.verb) {
        case 'ADD':
          action.secondIndex = action.secondIndex + (action.location === 'ABOVE' ? 0 : 1);

          const newWorkPackage = utils.createNewWorkPackage(
            newText,
            curProject,
            response.template,
            isWorkflow,
            true,
          );
          let curWorkpackages;
          if (isLibraryChange) {
            newWorkPackage.refId = null;
            newWorkPackage.tasks.forEach(task => {
              task.refId = null;
            });
            if (curDeliverable) {
              curWorkpackages = curProject.library.components.deliverables[action.index - 2].workPackages;
              newLibStack.push({
                screen: NAV.BOARD_DETAIL,
                item: { d: action.index, w: action.secondIndex, t: -1 },
                level: 'w',
              });
            } else {
              curWorkpackages = curProject.library.components.workPackages;
              newLibStack.push({
                screen: NAV.BOARD_DETAIL,
                item: { d: 1, w: action.secondIndex, t: -1 },
                level: 'w',
              });
            }
          } else {
            curWorkpackages = curProject.deliverables[action.index].workPackages;
            newWBSStack.push({
              screen: NAV.BOARD_DETAIL,
              item: { d: currentDeliverableIndex, w: action.secondIndex, t: -1 },
              level: 'w',
            });
          }
          // const deliverableIsValid =
          //     action.index >= 0 &&
          //     action.index < curProject.deliverables.length;
          const workPackageIsValid = action.secondIndex >= 0 && action.secondIndex <= curWorkpackages.length;

          if (workPackageIsValid) {
            curWorkpackages.splice(action.secondIndex, 0, newWorkPackage);
            currentDeliverableIndex = action.index;
            currentWorkPackageIndex = action.secondIndex;
            utils.refreshStats(curProject);

            dispatch(
              analyticsEvent(getState, 'Pro.Del.WorkPackage.add', {
                project: curProject.name,
                deliverable: curDeliverable ? curDeliverable.name : 'wp library',
                workPackage: newText,
              }),
            );
          }
          break;
        case 'EDIT':
          curWorkPackage.name = newText;
          break;

        case 'DELETE':
          dispatch(
            analyticsEvent(getState, 'Pro.Del.WorkPackage.delete', {
              project: curProject.name,
              deliverable: curDeliverable ? curDeliverable.name : 'wp library',
              workPackage: curWorkPackage.name,
            }),
          );
          if (curDeliverable) {
            curDeliverable.workPackages.splice(action.secondIndex, 1);
          } else {
            curProject.library.components.workPackages.splice(action.secondIndex, 1);
          }

          currentDeliverableIndex = action.index;
          currentWorkPackageIndex = action.secondIndex;
          utils.refreshStats(curProject);

          const stackTop = curStack[curStack.length - 1];
          stackTop.item.w -= 1;

          break;
        default:
      }

      Object.assign(viewState, {
        currentWorkPackage: currentWorkPackageIndex,
        currentDeliverable: currentDeliverableIndex,
        wbsCardsUpdateTS: new Date().getTime(),
        prevWindow: isLibraryChange ? NAV.LIBRARY_BOARD : viewState.prevWindow,
        wbsStack: newWBSStack,
        libStack: newLibStack,
        form: null,
        alert: null,
      });

      saveProjectData(curProject, viewState, dispatch, firebase, user);
    }
    if (action.target === 'DELIVERABLE') {
      let newWBSStack = viewState.wbsStack;
      let newLibStack = viewState.libStack;
      console.log('action', action);
      switch (action.verb) {
        case 'ADD':
          action.index = action.index + (action.location === 'ABOVE' ? 0 : 1);
          const newDeliverable = utils.createNewDeliverable(
            newText,
            curProject,
            response.template ? response.template : undefined,
            isWorkflow,
            true,
          );
          let curDeliverables = curProject.deliverables;
          let delIndex = action.index;
          if (isLibraryChange) {
            curDeliverables = curProject.library.components.deliverables;
            action.index = action.index - 2;
            if (action.index < 0 || action.index > curDeliverables.length) action.index = 0;
            newDeliverable.refId = null;
            newDeliverable.workPackages.forEach(wp => {
              wp.refId = null;
              wp.tasks.forEach(task => {
                task.refId = null;
              });
            });
            newLibStack.push({
              screen: NAV.BOARD_DETAIL,
              item: { d: action.index + 2, w: -1, t: -1 },
              level: 'd',
            });
          } else {
            newWBSStack.push({
              screen: NAV.BOARD_DETAIL,
              item: { d: action.index, w: -1, t: -1 },
              level: 'd',
            });
          }
          curDeliverables.splice(action.index, 0, newDeliverable);
          currentWorkPackageIndex = 0;
          currentDeliverableIndex = delIndex;
          utils.refreshStats(curProject);

          dispatch(
            analyticsEvent(getState, 'Pro.Del.add', {
              project: curProject.name,
              deliverable: curDeliverables[action.index].name,
            }),
          );
          break;
        case 'EDIT':
          curDeliverable.name = newText;
          break;
        case 'DELETE':
          curDeliverables = curProject.deliverables;
          if (isLibraryChange) {
            curDeliverables = curProject.library.components.deliverables;
          }
          if (curDeliverables.length >= (isLibraryChange ? 0 : 2)) {
            dispatch(
              analyticsEvent(getState, 'Pro.Del.delete', {
                project: curProject.name,
                deliverable: curDeliverables[action.index - (isLibraryChange ? 2 : 0)].name,
              }),
            );

            curDeliverables.splice(action.index - (isLibraryChange ? 2 : 0), 1);
            currentTaskIndex = 0;
            currentWorkPackageIndex = 0;
            currentDeliverableIndex = 0;
            utils.refreshStats(curProject);
          } else console.error(`Cannot delete last deliverable ${commandString}`);
          break;
        default:
      }

      Object.assign(viewState, {
        currentTask: currentTaskIndex,
        currentWorkPackage: currentWorkPackageIndex,
        currentDeliverable: currentDeliverableIndex,
        prevWindow: NAV.BOARD,
        wbsCardsUpdateTS: new Date().getTime(),
        libCardsUpdateTS: new Date().getTime(),
        wbsStack: newWBSStack,
        libStack: newLibStack,
        form: null,
        alert: null,
      });
      saveProjectData(curProject, viewState, dispatch, firebase, user);
    }

    if (action.target === 'PROJECT') {
      let updateProject = false;
      switch (action.verb) {
        case 'ADD':
          if (!newText || newText === '') return;

          // Figure out which template to use.
          let templateIndex = 0;
          if (typeof response.template !== 'undefined') {
            templateIndex = workflows.findIndex(template => template.name === response.template);
            if (templateIndex === -1) templateIndex = 0;
            console.log(`AddProject(${response.template}, ${templateIndex})`);
          }
          // TODO use template selected to create project
          let newProject = utils.createNewProject(newText, user.email, workflows[templateIndex]);
          firebase.db
            .collection('projects')
            .add(newProject)
            .then(docRef => {
              newProject.id = docRef.id;
              projects.push(newProject);
              // The sort order is projects vs templates
              // project name alphabetic
              orderProjects(projects);
              // projects.sort((p1, p2) =>
              //   p1.template === p2.template
              //     ? p1.name.toLowerCase < p2.name.toLowerCase
              //       ? -1
              //       : p1.name.toLowerCase === p2.name.toLowerCase
              //       ? 0
              //       : 1
              //     : p1.template
              //     ? 1
              //     : -1,
              // );
              viewState.currentProjectIndex = projects.findIndex(
                curProject => curProject.id === newProject.id,
              );
              if (currentProjIndex < 0 || currentProjIndex >= projects.length) {
                viewState.currentProjectIndex = 0;
              }
              viewState.currentDeliverable = 0;
              viewState.currentWorkPackage = 0;
              viewState.currentTask = 0;
              viewState.form = null;
              viewState.alert = null;
              viewState.curWindow = NAV.BOARD;
              viewState.wbsStack = [
                {
                  screen: NAV.BOARD,
                  item: { d: -1, w: -1, t: -1 },
                  level: 'p',
                },
              ]; // reset wbs nav stack
              viewState.libStack = [
                {
                  screen: NAV.LIBRARY_BOARD,
                  item: { d: -1, w: -1, t: -1 },
                  level: 'p',
                },
              ]; // reset lib nav stack
              dispatch(
                analyticsEvent(getState, 'Project.add', {
                  project: curProject.name,
                  template: curProject.templateName,
                }),
              );
              dispatch(updateProjects(projects, viewState, true));
            });
          break;
        case 'SHARE':
          updateProject = true;
          curProject.sharedWith = newText.replace(/\n/g, ',').split(',');
          break;
        case 'NEW_DATA': // user has accepted external changes sent from firebase
          if (viewState.updatedDoc) {
            projects[viewState.currentProjectIndex] = viewState.updatedDoc;
            viewState.updatedDoc = null;
            viewState.form = null;
            viewState.alert = null;
            dispatch(updateProjects(projects, viewState));
          }
          break;
        case 'DELETE':
          if (projects.length > 1) {
            dispatch(
              analyticsEvent(getState, 'Project.delete', {
                project: curProject.name,
              }),
            );

            curProject.deletedDate = new Date();
            projects.splice(viewState.currentProjectIndex, 1);
            viewState.currentProjectIndex = 0;
            viewState.currentDeliverable = 0;
            viewState.currentWorkPackage = 0;
            viewState.currentTask = 0;
            viewState.form = null;
            viewState.alert = null;
            viewState.curWindow = NAV.DASHBOARD;
            viewState.wbsStack = [
              {
                screen: NAV.BOARD,
                item: { d: -1, w: -1, t: -1 },
                level: 'p',
              },
            ]; // reset wbs nav stack
            viewState.libStack = [
              {
                screen: NAV.LIBRARY_BOARD,
                item: { d: -1, w: -1, t: -1 },
                level: 'p',
              },
            ]; // reset lib nav stack
            dispatch(updateUsibilityReport(utils.getProjectAlerts(projects)));
            saveProjectData(curProject, viewState, dispatch, firebase, user);
          } else console.error(`Cannot delete project ${curProject.name}: ${commandString}`);
          break;
        default:
      }

      if (updateProject) {
        saveProjectData(curProject, null, dispatch, firebase, user);
      }
    }
    if (action.target === 'WORKFLOW') {
      switch (action.verb) {
        case 'SAVE':
          dispatch(
            analyticsEvent(getState, 'Workflow.add', {
              templateName: newText,
              project: curProject.name,
            }),
          );
          firebase.db.createTemplate(newText, curProject, this.state.user, newTemplate => {
            workflows.push(newTemplate);

            // dispatch(updateTemplates(templates));

            console.log(`Template Save ${newTemplate.id}`);
          });
          break;
        case 'DELETE':
          dispatch(
            analyticsEvent(getState, 'Workflow.delete', {
              workflow: curProject.name,
            }),
          );
          const curTemplateSetId = viewState.currentWorkflowSetId;
          if (workflows[curTemplateSetId].length > 1) {
            curProject.deletedDate = new Date();
            viewState.currentWorkflowIndex = 0;
            viewState.currentWorkflowSetId = constants.PUBLIC_GROUP_ID;
            viewState.currentProjectIndex = 0;
            viewState.currentDeliverable = 0;
            viewState.currentWorkPackage = 0;
            viewState.currentTask = 0;
            viewState.form = null;
            viewState.alert = null;
            // viewState.curWindow = NAV.DASHBOARD;
            viewState.wbsStack = [
              {
                screen: NAV.BOARD,
                item: { d: -1, w: -1, t: -1 },
                level: 'p',
              },
            ]; // reset wbs nav stack
            viewState.libStack = [
              {
                screen: NAV.LIBRARY_BOARD,
                item: { d: -1, w: -1, t: -1 },
                level: 'p',
              },
            ]; // reset lib nav stack
            workflows[curTemplateSetId].splice(action.secondIndex, 1);
            dispatch(updateUsibilityReport(utils.getProjectAlerts(projects)));
            saveProjectData(curProject, viewState, dispatch, firebase, user);
          } else console.error(`Cannot delete last template ${commandString}`);
          break;
        case 'HELP':
          break;
        default:
      }
    }
  };
}

export function handleNo(e) {
  return (dispatch, getState) => {
    let { commandString } = getState();

    console.log(`handleNo(${commandString}`);
    dispatch(resetUIParms());
  };
}

export function handleMenu(commandString) {
  return (dispatch, getState) => {
    const reduxStore = getState();
    const curProject = utils.getCurrentProjectOrWorkflow(reduxStore);
    let { firebase, projects, workflows, viewState, user, group } = reduxStore;

    let action = utils.parseCommand(commandString);
    let curDeliverable;
    let curWorkPackage;
    let curStack = viewState.wbsStack;
    const isLibraryChange = viewState.curWindow === NAV.LIBRARY_BOARD;
    const isWorkflow = viewState.currentProjectType === 'Workflow';

    try {
      try {
        if (isLibraryChange) {
          curStack = viewState.libStack;
          if (viewState.currentDeliverable > 1) {
            curDeliverable = curProject.library.components.deliverables[viewState.currentDeliverable - 2];
          } else {
            curDeliverable = null;
          }
          if (!curDeliverable) {
            if (viewState.currentDeliverable === 0) {
              curWorkPackage = null;
            } else {
              // (viewState.currentDeliverable === 1)
              curWorkPackage = curProject.library.components.workPackages[viewState.currentWorkPackage];
            }
          } else {
            curWorkPackage = curDeliverable.workPackages[viewState.currentWorkPackage];
          }
        } else {
          curDeliverable = curProject.deliverables[viewState.currentDeliverable];
          curWorkPackage = curDeliverable.workPackages[viewState.currentWorkPackage];
        }
      } catch (error) {
        if (!(action.target === 'PROJECT' && action.verb === 'DELETE')) {
          throw new Error(`missing deliverables or workpackages in handleYes : ${error}`);
        }
      }
    } catch (error) {
      if (!(action.target === 'PROJECT' && (action.verb === 'DELETE' || action.verb === 'SELECT'))) {
        throw new Error(`missing deliverables or workpackages in handleMenu : ${error}`);
      }
    }
    console.log(`handleMenu(${commandString})=${JSON.stringify(action, null, 2)}`);

    if (action.target === 'ANALYTICS')
      // old can be removed
      switch (action.verb) {
        case 'SHOW':
          Object.assign(viewState, {
            showingAnalytics: true,
          });
          dispatch(updateViewState(viewState));
          break;

        case 'HIDE':
          Object.assign(viewState, {
            showingAnalytics: false,
          });
          dispatch(updateViewState(viewState));
          break;

        default:
          break;
      }

    if (action.target === 'TASK') {
      let curTasks;
      if (isLibraryChange) {
        if (viewState.currentDeliverable === 0) {
          // cur pointing to Tasks col in library
          curTasks = curProject.library.components.tasks;
        } else {
          curTasks = curWorkPackage.tasks;
        }
      } else {
        curTasks = curWorkPackage.tasks;
      }
      switch (action.verb) {
        case 'ADD':
          Object.assign(viewState, {
            form: true,
            textLabel: `Name`,
            formType: 'TASK',
            title: `New task - enter a name and choose a template`,
            text: '',
            formType: 'TASK-ADD',
          });
          dispatch(processCommand(commandString, viewState));
          break;
        case 'OPEN':
          curStack.push({
            screen: NAV.BOARD_DETAIL,
            item: {
              d: viewState.currentDeliverable,
              w: viewState.currentWorkPackage,
              t: action.index,
            },
            level: 't',
          });
          Object.assign(viewState, {
            currentTask: action.index,
            curWindow: isLibraryChange ? NAV.LIBRARY_BOARD : NAV.BOARD,
            prevWindow: isLibraryChange ? NAV.LIBRARY_BOARD : NAV.BOARD,
          });
          dispatch(updateViewState(viewState));
          break;
        /**
         * @deprecated action PASTE
         */
        case 'PASTE':
          // check to make sure that we have a copied item
          let newTask = null;
          if (viewState.copyContent !== null) {
            newTask = utils.copyTask(
              `${viewState.copyContent.name} ${viewState.copyMode === 'COPY' ? 'copy' : ''}`,
              viewState.copyContent,
              curProject,
              viewState.copyMode,
              isWorkflow,
              false,
            );
            if (isLibraryChange) {
              newTask.refId = null;
            }
            action.index = action.index + (action.location === 'ABOVE' ? 0 : 1);
            curTasks.splice(action.index, 0, newTask);
            utils.refreshStats(curProject);

            saveProjectData(curProject, null, dispatch, firebase, user);
          }
          break;
        case 'PIN':
          if (isLibraryChange) return; // cannot change pin of template components
          let curTask = curTasks[action.index];
          curTask.pinned = !curTask.pinned;
          saveProjectData(curProject, viewState, dispatch, firebase, user);
          break;
        case 'CUT':
        case 'COPY':
          Object.assign(viewState, {
            copyMode: action.verb,
            copyType: 'task',
            copyContent: JSON.parse(JSON.stringify(curTasks[action.index])),
          });
          if (action.verb === 'CUT') {
            curTasks.splice(action.index, 1);
            if (curTasks.length === 0) {
              curTasks.push(utils.createNewTask('New Task', curProject, undefined, isWorkflow, false));
            }

            let nextCurrentTaskIndex = action.index - 1;
            if (nextCurrentTaskIndex >= curTasks.length) {
              nextCurrentTaskIndex = curTasks.length - 1;
            }
            Object.assign(viewState, {
              currentTaskIndex: nextCurrentTaskIndex,
            });

            utils.refreshStats(curProject);

            // const stackTop = curStack[curStack.length - 1];
            // stackTop.item.w -= 1;

            saveProjectData(curProject, viewState, dispatch, firebase, user);
          } else {
            const curTask = curTasks[action.index];
            navigator.clipboard.writeText(`${curTask.refId} - ${curTask.name} `);
            dispatch(updateViewState(viewState));
          }
          break;
        case 'TOWP': // convert task to workpackage
          const selectedTask = curTasks[action.index];
          const newWP = utils.createNewWorkPackage(
            selectedTask.name,
            curProject,
            undefined,
            isWorkflow,
            false,
          );
          // move over relavent pieces... description/guidance...
          newWP.assignedTo = selectedTask.assignedTo;
          newWP.isPriority = selectedTask.isPriority;
          newWP.description = selectedTask.description;
          newWP.richTextNote = selectedTask.richTextNote;

          curDeliverable.workPackages.unshift(newWP);
          curTasks.splice(action.index, 1);
          utils.refreshStats(curProject);
          // change focus to one level up ... viewing new WP in deliverables detail

          const newStack = curStack;
          const topStack = newStack[newStack.length - 1];
          topStack.item.w = 0;
          topStack.item.t = -1;
          topStack.level = 'd';
          Object.assign(viewState, {
            currentWorkPackage: 0,
            currentTask: 0,
            wbsStack: isLibraryChange ? viewState.wbsStack : newStack,
            libStack: isLibraryChange ? newStack : viewState.libStack,
          });
          dispatch(updateViewState(viewState));
          break;
        case 'EDIT':
          Object.assign(viewState, {
            form: true,
            title: `Edit task #${curTasks[action.index].refId}:`,
            textLabel: 'Task',
            text: curTasks[action.index].name,
            formType: 'TASK-EDIT',
          });
          dispatch(processCommand(commandString, viewState));
          break;
        case 'DELETE':
          // if (curTasks.length > 1) {
          Object.assign(viewState, {
            alert: true,
            alertYesButton: 'yes',
            title: 'Delete the following task?',
            textLabel: 'Task',
            text: curTasks[action.index].name,
          });
          dispatch(processCommand(commandString, viewState));
          // } else {
          //   Object.assign(viewState, {
          //     alert: true,
          //     alertYesButton: 'none',
          //     title: 'Cannot delete the last task.',
          //     textLabel: 'Task',
          //     text: curTasks[action.index].name,
          //   });
          //   dispatch(processCommand('', viewState));
          // }
          break;
        default:
          break;
      }
    }
    let curDeliverables;
    let curDel;
    if (action.target === 'WORK PACKAGE') {
      switch (action.verb) {
        case 'ADD':
          if (isLibraryChange && action.index === 0) return; // cannot add workpackage to Tasks library

          console.log('New task group - enter a name and choose a workflow');
          Object.assign(viewState, {
            form: true,
            formType: 'WORKPACKAGE-ADD',
            textLabel: `Name`,
            title: `Add a Task Group`,
            text: '',
          });
          dispatch(processCommand(commandString, viewState));
          break;
        case 'OPEN':
          curStack.push({
            screen: NAV.BOARD_DETAIL,
            item: {
              d: action.index,
              w: action.secondIndex,
              t: -1,
            },
            level: 'w',
          });
          Object.assign(viewState, {
            currentDeliverable: action.index,
            currentWorkPackage: action.secondIndex,
            curWindow: isLibraryChange ? NAV.LIBRARY_BOARD : NAV.BOARD,
            prevWindow: isLibraryChange ? NAV.LIBRARY_BOARD : NAV.BOARD,
          });
          dispatch(updateViewState(viewState));
          break;
        case 'PASTE':
          // check to make sure that we have a copied item
          let newWorkPackage = null;
          if (viewState.copyContent !== null && viewState.copyType === 'workPackage') {
            newWorkPackage = utils.copyWorkPackage(
              `${viewState.copyContent.name} ${viewState.copyMode === 'COPY' ? 'copy' : ''}`,
              viewState.copyContent,
              curProject,
              viewState.copyMode,
              isWorkflow,
              false,
            );
            if (isLibraryChange) {
              newWorkPackage.refId = null;
              newWorkPackage.tasks.forEach(task => {
                task.refId = null;
              });
            }
            if (curDeliverable) {
              curDeliverable.workPackages.splice(action.secondIndex, 0, newWorkPackage);
            } else {
              curProject.library.components.workPackages.splice(action.secondIndex, 0, newWorkPackage);
            }
            utils.refreshStats(curProject);

            // reset copy & paste - ie, only allow one paste per copy
            Object.assign(viewState, {
              copyType: null,
              copyContent: null,
            });
            saveProjectData(curProject, viewState, dispatch, firebase, user);
          }
          break;
        case 'PIN':
          if (isLibraryChange) return; // cannot change pin of template components
          curDeliverables = curProject.deliverables;
          let curWP = curDeliverables[action.index].workPackages[action.secondIndex];
          curWP.pinned = !curWP.pinned;
          saveProjectData(curProject, viewState, dispatch, firebase, user);
          break;
        case 'SPLIT':
          // currentDeliverableIndex = action.index;
          // currentWorkPackageIndex = action.secondIndex;
          curWP = curDeliverable.workPackages[action.secondIndex];
          dispatch(splitWorkPackage(curDeliverable, curWP));
          break;
        case 'MERGE':
          // curDeliverable = curProject.deliverables[viewState.currentDeliverable];  // done above
          // curWorkPackage = curDeliverable.workPackages[viewState.currentWorkPackage];
          const workPackage2 = curDeliverable.workPackages[viewState.mergeWPs.w];

          curWorkPackage.tasks.push(...workPackage2.tasks);
          if (curWorkPackage.name !== workPackage2.name) {
            curWorkPackage.name = `${curWorkPackage.name}-${workPackage2.name}`;
          }
          curDeliverable.workPackages.splice(viewState.mergeWPs.w, 1);
          utils.refreshStats(curProject);
          Object.assign(viewState, {
            mergeWPs: null,
            wbsCardsUpdateTS: new Date().getTime(),
            libCardsUpdateTS: new Date().getTime(),
          });
          saveProjectData(curProject, viewState, dispatch, firebase, user);
          break;
        case 'EDIT':
          Object.assign(viewState, {
            form: true,
            textLabel: `${action.target.toLowerCase()}`,
            title: `Edit the ${action.target.toLowerCase()} title`,
            formType: 'WORKPACKAGE-EDIT',
            text: curWorkPackage.name,
          });
          dispatch(processCommand(commandString, viewState));
          break;
        case 'CUT':
        case 'COPY':
          // copy workpackage to viewState 'clipboard' as is. Paste (above) will make valid copy with updated uuids
          Object.assign(viewState, {
            copyMode: action.verb,
            copyType: 'workPackage',
            copyContent: JSON.parse(JSON.stringify(curWorkPackage)),
          });
          if (action.verb === 'CUT') {
            if (curDeliverable) {
              curDeliverable.workPackages.splice(action.secondIndex, 1);
            } else {
              curProject.library.components.workPackages.splice(action.secondIndex, 1);
            }

            Object.assign(viewState, {
              currentDeliverableIndex: action.index,
              currentWorkPackageIndex: action.secondIndex,
            });

            utils.refreshStats(curProject);

            const stackTop = curStack[curStack.length - 1];
            stackTop.item.w -= 1;

            saveProjectData(curProject, viewState, dispatch, firebase, user);
          } else {
            const curWP = curDeliverable.workPackages[action.secondIndex];
            navigator.clipboard.writeText(`${curWP.refId} - ${curWP.name} `);
            dispatch(updateViewState(viewState));
          }

          break;
        case 'TODEL': // convert workpackage to deliverable
          const selectedWP = curWorkPackage;
          const newDel = utils.createNewDeliverable(
            selectedWP.name,
            curProject,
            undefined,
            isWorkflow,
            false,
          );
          if (isLibraryChange) {
            newDel.refId = null;
            newDel.workPackages.forEach(wp => {
              wp.refId = null;
              wp.tasks.forEach(task => {
                task.refId = null;
              });
            });
          }
          // move over relavent pieces... description/guidance...
          newDel.assignedTo = selectedWP.assignedTo;
          newDel.isPriority = selectedWP.isPriority;
          newDel.description = selectedWP.description;
          newDel.richTextNote = selectedWP.richTextNote;

          if (isLibraryChange) {
            curProject.library.components.deliverables.unshift(newDel);
            if (curDeliverable) {
              curDeliverable.workPackages.splice(action.secondIndex, 1);
            } else {
              curProject.library.components.workPackages.splice(action.secondIndex, 1);
            }
          } else {
            curProject.deliverables.unshift(newDel);
            curDeliverable.workPackages.splice(action.secondIndex, 1);
          }
          utils.refreshStats(curProject);
          // change focus to one level up ... viewing new WP in deliverables detail

          const nextStack = [
            {
              screen: NAV.BOARD,
              item: { d: 0, w: -1, t: -1 },
              level: 'p',
            },
          ];

          Object.assign(viewState, {
            currentDeliverable: 0,
            currentWorkPackage: 0,
            currentTask: 0,
            wbsStack: isLibraryChange ? viewState.wbsStack : nextStack,
            libStack: isLibraryChange ? nextStack : viewState.libStack,
          });
          dispatch(updateViewState(viewState));
          break;
        case 'DELETE':
          //cannot delete last workpackage
          // if (
          //   curProject.deliverables[action.index].workPackages
          //     .length > 1
          // ) {
          Object.assign(viewState, {
            alert: true,
            textLabel: `${action.target.toLowerCase()}`,
            alertYesButton: 'yes',
            title: `Delete the following ${action.target.toLowerCase()}?`,
            text: `${curWorkPackage.name}`,
          });
          dispatch(processCommand(commandString, viewState));
          // } else {
          //   Object.assign(viewState, {
          //     alert: true,
          //     alertYesButton: 'none',
          //     title: `Cannot delete the last ${action.target.toLowerCase()}.`,
          //     textLabel: `${action.target.toLowerCase()}`,
          //     text: `Cannot delete ${
          //       curProject.deliverables[action.index].workPackages[
          //         action.secondIndex
          //       ].name
          //     }`,
          //   });
          //   dispatch(processCommand('', viewState));
          // }
          break;
        default:
      }
    }
    if (action.target === 'DELIVERABLE') {
      console.log('action', action);
      switch (action.verb) {
        case 'ADD':
          console.log('action', action);
          Object.assign(viewState, {
            form: true,
            formType: 'DELIVERABLE-ADD',
            textLabel: `Name`,
            title: `New ${action.target.toLowerCase()} - enter a name and choose a template`,
            text: '',
          });
          dispatch(processCommand(commandString, viewState));
          break;
        case 'OPEN':
          curStack.push({
            screen: NAV.BOARD_DETAIL,
            item: {
              d: action.index,
              w: -1,
              t: -1,
            },
            level: 'd',
          });
          Object.assign(viewState, {
            currentDeliverable: action.index,
            curWindow: isLibraryChange ? NAV.LIBRARY_BOARD : NAV.BOARD,
            prevWindow: isLibraryChange ? NAV.LIBRARY_BOARD : NAV.BOARD,
          });
          dispatch(updateViewState(viewState));
          break;
        case 'PASTE':
          // check to make sure that we have a copied item
          let newDeliverable = null;
          if (viewState.copyContent !== null && viewState.copyType === 'deliverable') {
            newDeliverable = utils.copyDeliverable(
              `${viewState.copyContent.name} ${viewState.copyMode === 'COPY' ? 'copy' : ''}`,
              viewState.copyContent,
              curProject,
              viewState.copyMode,
              isWorkflow,
              false,
            );
            let curDeliverables = curProject.deliverables;
            if (isLibraryChange) {
              curDeliverables = curProject.library.components.deliverables;
              newDeliverable.refId = null;
              newDeliverable.workPackages.forEach(wp => {
                wp.refId = null;
                wp.tasks.forEach(task => {
                  task.refId = null;
                });
              });
            }
            // temporary trap/hack to avoid duplicate deliverable names
            // duplicate deliverable names screws up DelivearableBoard.js columns var calculation as that code relies on unique deliverable names (should be changed to uuids)
            curDeliverables.forEach(deliverable => {
              if (newDeliverable.name === deliverable.name)
                newDeliverable.name = `${newDeliverable.name} ${viewState.copyMode === 'COPY' ? 'copy' : ''}`;
            });

            curDeliverables.splice(action.index - (isLibraryChange ? 2 : 0), 0, newDeliverable);
            utils.refreshStats(curProject);

            // reset copy & paste - ie, only allow one paste per copy
            Object.assign(viewState, {
              copyType: null,
              copyContent: null,
            });
            saveProjectData(curProject, viewState, dispatch, firebase, user);
          }
          break;
        case 'PIN':
          if (isLibraryChange) return; // cannot change pin of template components
          curDeliverables = curProject.deliverables;
          curDel = curDeliverables[action.index];
          curDel.pinned = !curDel.pinned;
          saveProjectData(curProject, viewState, dispatch, firebase, user);
          break;
        case 'CUT':
        case 'COPY':
          // copy workpackage to viewState 'clipboard' as is. Paste (above) will make valid copy with updated uuids
          let curDeliverables = curProject.deliverables;
          if (isLibraryChange) {
            curDeliverables = curProject.library.components.deliverables;
          }
          curDel = curDeliverables[action.index];

          Object.assign(viewState, {
            copyMode: action.verb,
            copyType: 'deliverable',
            copyContent: JSON.parse(
              JSON.stringify(curDeliverables[action.index - (isLibraryChange ? 2 : 0)]),
            ),
          });

          // navigator.clipboard.writeText(`${curDel.name} `);
          // dispatch(updateViewState(viewState));

          if (action.verb === 'CUT') {
            if (curDeliverable) {
              // remove old
              curDeliverables.splice(action.index, 1);
            } else {
              curProject.library.components.deliverables.splice(action.index, 1);
            }

            Object.assign(viewState, {
              currentDeliverableIndex: action.index,
              currentWorkPackageIndex: 0,
            });

            utils.refreshStats(curProject);

            const stackTop = curStack[curStack.length - 1];
            stackTop.item.d -= 1;

            saveProjectData(curProject, viewState, dispatch, firebase, user);
          } else {
            navigator.clipboard.writeText(`${curDel.name} `);
            dispatch(updateViewState(viewState));
          }
          break;

        case 'TOPROJ': // convert del to project
          break;
        case 'EDIT':
          console.log('action');
          Object.assign(viewState, {
            form: true,
            textLabel: `${action.target.toLowerCase()}`,
            title: `Edit the ${action.target.toLowerCase()} here.`,
            text: curDeliverable.name,
            formType: 'DELIVERABLE-EDIT',
          });
          dispatch(processCommand(commandString, viewState));
          break;
        case 'DELETE':
          //cannot delete last deliverable
          if (curProject.deliverables.length > 1 || isLibraryChange) {
            Object.assign(viewState, {
              alert: true,
              alertYesButton: 'yes',
              title: `Delete the following ${action.target.toLowerCase()}?
              Warning! All associated task groups and tasks for this deliverable will be deleted.`,
              textLabel: `${action.target.toLowerCase()}`,
              text: `${curDeliverable.name}`,
            });
            dispatch(processCommand(commandString, viewState));
          } else {
            Object.assign(viewState, {
              alert: true,
              alertYesButton: 'none',
              title: `Cannot delete the last ${action.target.toLowerCase()}.`,
              textLabel: `${action.target.toLowerCase()}`,
              text: `You cannot delete ${curDeliverable.name}`,
            });
            dispatch(processCommand('', viewState));
          }
          break;
        default:
      }
    }

    if (action.target === 'PROJECT') {
      let newProject;
      switch (action.verb) {
        case 'ADD':
          Object.assign(viewState, {
            form: true,
            formType: 'TEMPLATE',
            textLabel: 'New Project Name',
            title: `Create a new Project...`,
            text: '',
          });
          dispatch(processCommand(commandString, viewState));
          break;

        case 'OPEN':
          const newStack = [
            {
              screen: isLibraryChange ? NAV.LIBRARY_BOARD : NAV.BOARD,
              item: { d: 0, w: -1, t: -1 },
              level: 'p',
            },
          ];
          Object.assign(viewState, {
            currentDeliverable: 0,
            currentWorkPackage: 0,
            curWindow: isLibraryChange ? NAV.LIBRARY_BOARD : NAV.BOARD,
            wbsStack: isLibraryChange ? viewState.wbsStack : newStack,
            libStack: isLibraryChange ? newStack : viewState.libStack,
            projectScheduleReport: {
              overdue: false,
              today: false,
              soon: false,
              missing: false,
              justMe: false,
              priority: false,
              statusInProgress: false,
              statusDone: false,
              statusNotStarted: false,
              projectTeams: viewState.projectScheduleReport.projectTeams,
              projectTimeFilters: viewState.projectScheduleReport.projectTimeFilters,
            },
            projectScheduleScrollInfo: 0,
          });
          dispatch(updateViewState(viewState));
          break;
        case 'SELECT':
          Object.assign(viewState, {
            currentProjectIndex: action.index,
            currentDeliverable: 0,
            currentWorkPackage: 0,
            currentTask: 0,
            curWindow: NAV.BOARD,
            lastWindow: { ...viewState.lastWindow, [viewState.curProjectUUID]: NAV.BOARD },
            wbsStack: isLibraryChange
              ? viewState.wbsStack
              : [
                  {
                    screen: NAV.BOARD,
                    item: { d: -1, w: -1, t: -1 },
                    level: 'p',
                  },
                ], // reset wbs nav stack
            libStack: isLibraryChange
              ? [
                  {
                    screen: NAV.BOARD,
                    item: { d: -1, w: -1, t: -1 },
                    level: 'p',
                  },
                ]
              : viewState.libStack, // reset wbs nav stack
            title: '',
            textLabel: '',
            formType: '',
            text: '',
          });

          const nextProject = projects[viewState.currentProjectIndex];
          if (nextProject) {
            dispatch(updateViewState(viewState));
          } else {
            console.error(`something went wrong when loading user project`);
            signOutUser(dispatch, getState()); // something wrong with data, logout and reload after signin
          }

          break;
        case 'SHARE':
          const shareWithUsers = projects[viewState.currentProjectIndex].sharedWith
            ? projects[viewState.currentProjectIndex].sharedWith.join(', ')
            : '';
          Object.assign(viewState, {
            form: true,
            title: `Add users to your project here, separate email addresses with ','.`,
            textLabel: 'Shared With',
            text: shareWithUsers,
            formType: 'TEMPLATE',
          });
          dispatch(processCommand(commandString, viewState));
          break;

        case 'PRIVATETEMPLATE':
        case 'CORETEMPLATE':
        case 'TEMPLATE':
          newProject = utils.copyProject(user.email, projects[viewState.currentProjectIndex], true);
          newProject.template = projects[viewState.currentProjectIndex].template;

          // ie, some form of save to template
          newProject.workflowSetId =
            action.verb === 'TEMPLATE'
              ? constants.PUBLIC_GROUP_ID
              : action.verb === 'CORETEMPLATE'
              ? constants.CORE_SMART_TEMPLATES_ID
              : // ? constants.CORE_SMART_TEMPLATES_ID
                group.id;

          newProject.library = {
            components: {
              tasks: [],
              workPackages: [],
              deliverables: [],
            },
            level: 1,
            visible: true, // visible when selecting items
            locked: false,
            default: false,
            order: 1000, // put it at end of first level
          };
          newProject.workflowUUID = action.verb === 'TEMPLATE' ? newProject.uuid : null;
          newProject.firebaseCollection = 'workflows';

          firebase.db
            .collection('workflows')
            .add(newProject)
            .then(docRef => {
              if (action.verb === 'PRIVATETEMPLATE') {
                Object.assign(viewState, {
                  curWindow: NAV.SMARTTEMPLATES,
                });
                dispatch(updateViewState(viewState));
              }
            });
          break;

        case 'COPY': // copy project
          newProject = utils.copyProject(user.email, projects[viewState.currentProjectIndex], false);
          newProject.template = false;

          firebase.db
            .collection('projects')
            .add(newProject)
            .then(docRef => {
              newProject.id = docRef.id;
              projects.push(newProject);
              orderProjects(projects);

              viewState.currentProjectIndex = projects.findIndex(
                curProject => curProject.id === newProject.id,
              );
              if (viewState.currentProjectIndex < 0 || viewState.currentProjectIndex >= projects.length) {
                viewState.currentProjectIndex = 0;
              }
              viewState.currentDeliverable = 0;
              viewState.currentWorkPackage = 0;
              viewState.currentTask = 0;
              dispatch(updateProjects(projects, viewState, true));
            });
          break;

        case 'EDIT':
          Object.assign(viewState, {
            title: '',
            text: '',
            textLabel: '',
            formType: '',
          });
          dispatch(processCommand('', viewState));
          break;
        case 'DELETE':
          if (curProject.library && (curProject.library.default || curProject.library.level === 0)) return; // block deleting of critical workflows... should be trapped in other places, but this is a hard boundary

          //cannot delete last project
          if (user.email !== curProject.creator) {
            Object.assign(viewState, {
              alert: true,
              alertYesButton: 'none',
              textLabel: 'Project',
              formType: '',
              title: 'Request to delete project blocked.',
              text: `You do not have permission to delete the project ${curProject.name}`,
            });
            dispatch(processCommand(commandString, viewState));
            return;
          }

          if (projects.length > 1) {
            Object.assign(viewState, {
              alert: true,
              alertYesButton: 'yes',
              title: `Delete the following ${curProject.template ? 'workflow' : 'project'}?`,
              textLabel: 'Project',
              formType: '',
              text: `${curProject.name}`,
            });
            dispatch(processCommand(commandString, viewState));
          } else {
            Object.assign(viewState, {
              alert: true,
              alertYesButton: 'none',
              textLabel: 'Project',
              formType: '',
              title: 'Cannot delete the last project.',
              text: `Current last project is named ${curProject.name}`,
            });
            dispatch(processCommand(commandString, viewState));
          }
          break;
        default:
      }
    }

    if (action.target === 'WORKFLOW') {
      let numWFs = workflows.length;
      let curIndex;
      let done;
      let curLevel;
      let wfSplit;
      let wfIndex;
      let setId;
      switch (action.verb) {
        case 'SAVE':
          setId = action.index;
          wfIndex = action.secondIndex;
          action.index = workflows[setId].length;
          Object.assign(viewState, {
            form: true,
            textLabel: 'Workflow Name',
            formType: '',
            title: `Enter name for workflow.`,
          });
          dispatch(processCommand(commandString, viewState));
          break;

        case 'INDENT': // make child of parent
          setId = action.index;
          wfIndex = action.secondIndex;
          if (!workflows[setId][wfIndex].canIndent) return;
          workflows[setId][wfIndex].library.level++;
          curLevel = workflows[setId][wfIndex].library.level;
          curIndex = wfIndex + 1;
          done = false;
          while (curIndex < numWFs && !done) {
            // handle indent of children
            if (workflows[curIndex].library.level >= curLevel) {
              workflows[curIndex].library.level++;
              curIndex++;
            } else {
              done = true;
            }
          }
          utils.setSmartTemplateIndent(workflows);
          saveWorkflowsCore(workflows, getState);
          break;
        case 'OUTDENT':
          setId = action.index;
          wfIndex = action.secondIndex;
          if (!workflows[setId][wfIndex].canOutdent) return;
          curLevel = workflows[setId][wfIndex].library.level;
          workflows[setId][wfIndex].library.level--;
          curIndex = wfIndex + 1;
          done = false;
          while (curIndex < numWFs && !done) {
            // handle outdent of children
            if (workflows[curIndex].library.level > curLevel) {
              workflows[curIndex].library.level--;
              curIndex++;
            } else {
              done = true;
            }
          }
          done = false;
          const siblingsToMove = []; // move following siblings before this outdented item and it's children
          while (curIndex < numWFs && !done) {
            if (workflows[curIndex].library.level >= curLevel) {
              siblingsToMove.push(workflows[curIndex]);
              workflows.splice(curIndex, 1);
              numWFs = workflows.length;
            } else {
              if (workflows[curIndex].library.level < curLevel) done = true;
              curIndex++;
            }
          }
          curIndex = action.index;
          siblingsToMove.forEach(wf => {
            workflows.splice(curIndex, 0, wf);
            curIndex++;
          });
          utils.setSmartTemplateIndent(workflows);

          if (viewState.currentWorkflowIndex !== curIndex) {
            Object.assign(viewState, {
              currentWorkflowIndex: curIndex,
              currentWorkflowSetId: setId,
            });
            dispatch(updateViewState(viewState));
          }
          saveWorkflowsCore(workflows, getState);
          break;
        case 'OPEN':
          setId = action.index;
          wfIndex = action.secondIndex;
          const newStack = [
            {
              screen: NAV.BOARD,
              item: {
                d: 0,
                w: -1,
                t: -1,
              },
              level: 'p',
            },
          ];
          const newLibStack = [
            {
              screen: NAV.LIBRARY_BOARD,
              item: {
                d: 0,
                w: -1,
                t: -1,
              },
              level: 'p',
            },
          ];
          Object.assign(viewState, {
            currentDeliverable: 0,
            currentWorkPackage: 0,
            currentTask: 0,
            wbsStack: newStack,
            libStack: newLibStack,
            curWindow: NAV.BOARD,
            lastWindow: { ...viewState.lastWindow, [viewState.curProjectUUID]: NAV.BOARD },
          });
          dispatch(updateViewState(viewState));
          break;

        case 'DELETE':
          setId = action.index;
          wfIndex = action.secondIndex;
          //TODO Delete a template
          Object.assign(viewState, {
            alert: true,
            alertYesButton: 'yes',
            title: `Delete the following ${curProject.template ? 'workflow' : 'project'}?`,
            textLabel: 'Workflow',
            formType: '',
            text: `${curProject.name}`,
          });
          dispatch(processCommand(commandString, viewState));
          break;

        // case 'ACCESS':
        //   setId = action.index;
        //   wfIndex = action.secondIndex;
        //   Object.assign(viewState, {
        //     curWindow: NAV.TEMPLATE_ACCESS,
        //     currentWorkflowIndex: setId,
        //     currentWorkflowSetId: wfIndex,
        //   });
        //   dispatch(updateViewState(viewState));
        //   break;
        default:
      }
    }
  };
}
