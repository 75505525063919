import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

// import Tooltip from '@material-ui/core/Tooltip';

import * as USER from '../constants/user';
import { LiveTvRounded } from '@material-ui/icons';

const LEFT_COLUMN_WIDTH_PERCENT = '50%';
const COLUMN_WIDTH_PERCENT = '33%';
const FONT_COLOR = '#777';
const DARK_FONT_COLOR = '#666';
const accountStyles = makeStyles(theme => ({
  scrollContainer: {
    height: 'calc(100vh - 140px)', // required for scroll bars to work
    overflowY: 'auto',
    display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItem: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: '20px'
    // backgroundColor: 'yellow'
  },
  waitingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // height: '100%',
    // width: '100%',
    // zIndex: 5,
    backgroundColor: '#40404010',
  },
  waiting: {
    margin: 'auto',
  },
  curPlanColumn: {
    width: COLUMN_WIDTH_PERCENT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
    marginTop: '20px',
    marginLeft: '15px',
    marginRight: '15px',
    // backgroundColor: 'yellow',
  },
  planColumn: {
    width: COLUMN_WIDTH_PERCENT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
    marginTop: '20px',
    marginLeft: '15px',
    marginRight: '15px',
    // backgroundColor: 'green',
  },
  boxContainer: {
    display: 'flex',
    width: '360px',
    height: '520px',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
    marginTop: '40px',
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: theme.palette.secondary.main,
    borderRadius: 13,
  },
  curBoxContainer: {
    display: 'flex',
    width: '360px',
    height: '520px',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
    marginTop: '40px',
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: theme.palette.secondary.main,
    borderRadius: 13,
    backgroundColor: '#ffffdb40',
  },
  leftColumn: {
    width: LEFT_COLUMN_WIDTH_PERCENT,
    minWidth: '420px',
    // overflow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  rightColumn: {
    width: `calc(100% - ${LEFT_COLUMN_WIDTH_PERCENT})`,
    // overflow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.5em',
    fontWeight: 600,
    color: FONT_COLOR,
    marginTop: '40px',
  },
  expiredTitle: {
    fontSize: '1.8em',
    fontWeight: 600,
    color: 'red',
    marginTop: '20px',
  },
  expiryDate: {
    fontSize: '1.4em',
    fontWeight: 500,
  },
  plan: {
    fontSize: '2.7em',
    fontWeight: 600,
    color: DARK_FONT_COLOR,
    marginTop: '20px',
    marginBottom: '30px',
  },

  upgradeTitle: {
    fontSize: '1.8em',
    fontWeight: 600,
    color: FONT_COLOR,
  },
  upgradePlanTitle: {
    fontSize: '3em',
    fontWeight: 600,
    color: FONT_COLOR,
    marginTop: '15px',
    marginBottom: '25px',
  },
  basicPlanPrice: {
    fontSize: '2.5em',
    fontWeight: 600,
    color: DARK_FONT_COLOR,
    marginTop: '20px',
  },
  basicPlanInterval: {
    fontSize: '.9em',
    fontWeight: 500,
    color: DARK_FONT_COLOR,
    marginTop: '4px',
    marginBottom: '10px',
    marginLeft: '60px',
  },
  planFeatureTitle: {
    fontSize: '1.3em',
    fontWeight: 500,
    color: FONT_COLOR,
    alignSelf: 'flex-start',
    marginLeft: '20px',
    marginBottom: '5px',
  },
  planFeature: {
    fontSize: '1.3em',
    fontWeight: 500,
    color: FONT_COLOR,
    alignSelf: 'flex-start',
    marginLeft: '40px',
    marginBottom: '5px',
  },
  buttonContainer: {
    marginTop: '30px',
  },
  altButtonContainer: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  detailsButtonContainer: {
    marginTop: '30px',
    marginBottom: '40px',
  },
  orButton: {
    position: 'relative',
    right: '30px',
    top: '100px',
  },
}));
const showWaitingIndicator = styleName => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={true}
      invisible={true}
      onClick={() => {}}
    >
      <CircularProgress
        style={{
          color: '#52d1b570',
          marginTop: '25px',
          margin: 'auto',
        }}
        size={60}
      />
    </Backdrop>
  );
};

// Droppable container for Dragable WP cards
export const UserAccount = ({ firebase, user, productPlans }) => {
  const classes = accountStyles();
  const [planIndex, setPlanIndex] = React.useState({ 1: 0, 2: 0 });
  const [showWaiting, setShowWaiting] = React.useState(false);

  if (!user || !user.plan) {
    // if page loaded before user set - ie, app restarted from account page
    return showWaitingIndicator(classes.waitingContainer);
  }

  // let curPlan = user.plan.name; // todo at start of session read curPlan into store from firebase
  let curPlan = user.plan.displayName; // todo at start of session read curPlan into store from firebase
  const curPlanFeatures = user.plan.features;

  let expires = null;
  let expiryColor = FONT_COLOR;
  let expiredTitle;

  const activePlans = productPlans.slice(0, 3);

  const redirectURL = `${window.location.origin}/?acc=t`;
  // console.log(`======================= redirect: ${redirectURL}`)
  // currently this code only supports one upgradable plan.... need to expand it to accomodate more

  // user.plan = activePlans[1];  // for testing

  const isFreePlan = user.plan.name === USER.FREE_PLAN_NAME;
  if (!isFreePlan && user.plan.expired) {
    const dateStr = user.plan.expiry.format('MMM D, YYYY');
    if (user.plan.expired) {
      expires = `expired on: ${dateStr}`;
      expiredTitle = `previous ${user.plan.expiredPlan}`;
      expiryColor = 'red';
    } else {
      expires = `expires on: ${dateStr}`;
    }
  }

  if (user.plan.planId === 'manual') {
    return (
      <div className={classes.scrollContainer}>
        <div className={classes.container}>
          {activePlans.map((plan, pInd) => {
            if (pInd !== 1) {
              return <div className={classes.planColumn} />;
            }
            let title = 'assigned to the';

            const featureComponents = [];
            featureComponents.push(
              <div key={'fc-title'} className={classes.planFeatureTitle}>
                {`includes:`}
              </div>,
            );
            user.plan.features.forEach((feature, index) => {
              featureComponents.push(
                <div key={`fc-${index}`} className={classes.planFeature}>
                  {`${feature.name}: ${feature.value}`}
                </div>,
              );
            });
            return (
              <div className={classes.planColumn}>
                <div className={classes.curBoxContainer}>
                  <div className={classes.title}>{title}</div>
                  <div className={classes.plan}>{user.plan.displayName}</div>
                  <div style={{ marginRight: '20px', height: '120px' }}>{featureComponents}</div>
                  {expires && !user.plan.expired && (
                    <div className={classes.expiryDate} style={{ marginTop: '20px', color: expiryColor }}>
                      {expires}
                    </div>
                  )}
                  {expires && user.plan.expired && (
                    <div>
                      <div className={classes.expiredTitle}>{expiredTitle}</div>
                      <div className={classes.expiryDate} style={{ marginTop: '5px', color: expiryColor }}>
                        {expires}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {showWaiting && showWaitingIndicator(classes.waitingContainer)}
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.scrollContainer}>
        <div className={classes.container}>
          {activePlans.map((plan, pInd) => {
            const currentPlan = plan.name === user.plan.name;
            const isFreePlan = plan.name === USER.FREE_PLAN_NAME;
            let title = isFreePlan
              ? currentPlan
                ? 'currently using the'
                : 'change to the'
              : currentPlan
              ? `currently subscribed to`
              : 'subscribe to the';

            const selectedPlanPrice = plan.prices.length === 0 ? 0 : plan.prices[planIndex[pInd]];
            const altText =
              plan.prices.length === 0
                ? 'none'
                : selectedPlanPrice.interval === 'year'
                ? `pay monthly instead`
                : `pay annually instead`;

            const featureComponents = [];
            featureComponents.push(
              <div key={'fc-title'} className={classes.planFeatureTitle}>
                {`includes:`}
              </div>,
            );
            plan.features.forEach((feature, index) => {
              featureComponents.push(
                <div key={`fc-${index}`} className={classes.planFeature}>
                  {`${feature.name}: ${feature.value}`}
                </div>,
              );
            });
            return (
              <div
                className={currentPlan ? classes.curPlanColumn : classes.planColumn}
                // style={isFreePlan ? { marginRight: '30px' } : { marginRight: '0px' }}
              >
                <div className={currentPlan ? classes.curBoxContainer : classes.boxContainer}>
                  <div className={classes.title}>{title}</div>
                  <div className={classes.plan}>{plan.displayName}</div>
                  <div style={{ marginRight: '20px', height: '120px' }}>{featureComponents}</div>
                  {expires && !user.plan.expired && (
                    <div className={classes.expiryDate} style={{ marginTop: '20px', color: expiryColor }}>
                      {expires}
                    </div>
                  )}
                  {expires && user.plan.expired && (
                    <div>
                      <div className={classes.expiredTitle}>{expiredTitle}</div>
                      <div className={classes.expiryDate} style={{ marginTop: '5px', color: expiryColor }}>
                        {expires}
                      </div>
                    </div>
                  )}
                  {currentPlan && (!isFreePlan || user.plan.expired) && (
                    <Button
                      onClick={() => {
                        const getStripePortalLink = firebase.functions.httpsCallable(
                          'ext-firestore-stripe-payments-createPortalLink',
                        );
                        getStripePortalLink({
                          returnUrl: redirectURL,
                          locale: 'auto', // Optional, defaults to "auto"
                        })
                          .then(data => {
                            window.location.assign(data.data.url);
                          })
                          .catch(error => {
                            console.error(`Error redirecting user to Stripe Customer Portal: ${error}`);
                          });

                        setShowWaiting(true);
                      }}
                      color="secondary"
                      variant="contained"
                      size="small"
                      className={classes.detailsButtonContainer}
                      style={{
                        backgroundColor: '#00afa9',
                        color: 'white',
                        fontWeight: 600,
                      }}
                    >
                      {user.plan.expired ? `View Details` : `View Subscription Details`}
                    </Button>
                  )}
                  {!isFreePlan && !currentPlan && (
                    <Fragment>
                      <div className={classes.basicPlanPrice}>{`$${selectedPlanPrice.monthlyEq}/month`}</div>
                      <div className={classes.basicPlanInterval}>
                        {selectedPlanPrice.interval === 'year' ? `paid annually` : `paid every month`}
                      </div>
                      <Button
                        onClick={() => {
                          const newPlanIndex = { ...planIndex };
                          newPlanIndex[pInd] = newPlanIndex[pInd] === 0 ? 1 : 0;
                          setPlanIndex(newPlanIndex);
                        }}
                        color="secondary"
                        variant="outlined"
                        size="small"
                        className={classes.altButtonContainer}
                      >
                        {altText}
                      </Button>
                      <Button
                        onClick={() => {
                          // launch subscription sequence
                          // from https://console.firebase.google.com/u/0/project/project-assistant-beta/extensions/instances/firestore-stripe-payments?tab=details
                          // To subscribe the user to a specific pricing plan, create a new doc in the
                          // checkout_sessions collection for the user. The extension will update the
                          // doc with a Stripe Checkout session ID which you then use to redirect the
                          // user to the checkout page.

                          // create checkout session in stripe but posting a doc to the user's checkout_sessions

                          firebase.db
                            .collection('customers')
                            .doc(user.uid)
                            .collection('checkout_sessions')
                            .add({
                              price: selectedPlanPrice.id, // identifies product/plan and pricing option
                              success_url: redirectURL, // redirect to the current url
                              cancel_url: redirectURL,
                            })
                            .then(docRef => {
                              // Wait for the CheckoutSession to create a session in Stripe and get a response, via the doc
                              docRef.onSnapshot(snap => {
                                const { error, url } = snap.data();
                                if (error) {
                                  // Show an error to your customer and
                                  // inspect your Cloud Function logs in the Firebase console.
                                  alert(`An error occured: ${error.message}`);
                                }
                                if (url) {
                                  // We have a Stripe Checkout URL, let's redirect to Stripe
                                  window.location.assign(url);
                                  // afterwards it redirects back to here.... but how do we know if it succeeded... do we have to poll the dbase again?
                                }
                              });
                            })
                            .catch(error => {});

                          setShowWaiting(true);
                        }}
                        color="primary"
                        variant="contained"
                        size="large"
                        className={classes.buttonContainer}
                        style={{
                          backgroundColor: '#00afa9',
                          color: 'white',
                          fontWeight: 600,
                        }}
                      >
                        {`Subscribe Now - $${selectedPlanPrice.amount} per ${selectedPlanPrice.interval}`}
                      </Button>
                    </Fragment>
                  )}
                </div>
              </div>
            );
          })}
          {showWaiting && showWaitingIndicator(classes.waitingContainer)}
        </div>
      </div>
    );
  }
};
export default connect(state => {
  return {
    firebase: state.firebase,
    user: state.user,
    productPlans: state.productPlans,
  };
}, {})(UserAccount);
