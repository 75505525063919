import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import viewState from '../data/reducers/viewState';
import * as NAV from '../constants/navigation';
import * as USER from '../constants/user';

export default function OverLimitDialog({
  viewState,
  limitType,
  showLimitReachedDialog,
  dispatchUpdateViewState,
}) {
  // const [open, setOpen] = React.useState(false);

  const handleUpgrade = () => {
    viewState = Object.assign(viewState, {
      prevWindow: viewState.curWindow,
      curWindow: NAV.ACCOUNT,
    });
    dispatchUpdateViewState(viewState);
  };
  const handleCancel = () => {
    showLimitReachedDialog(null);
  };

  let title = `You have reached a limit of the ${USER.FREE_PLAN_NAME}`;
  let text;
  const buttonLabel = 'Upgrade Now';
  switch (limitType) {
    case 'addNew':
      text = `You are trying to create more than ${USER.FREE_PLAN_OWNED_PROJ_LIMIT} project${
        USER.FREE_PLAN_OWNED_PROJ_LIMIT === 1 ? '' : 's'
      }. Press ${buttonLabel} to change your plan and create more projects.`;
      break;
    case 'owned':
      text = `You can only open ${USER.FREE_PLAN_OWNED_PROJ_LIMIT === 1 ? '' : 'up to '}${
        USER.FREE_PLAN_OWNED_PROJ_LIMIT
      } project${
        USER.FREE_PLAN_OWNED_PROJ_LIMIT === 1 ? '' : 's'
      } that you have created. Press ${buttonLabel} to change your plan and create more projects.`;
      break;
    case 'shared':
      text = `You can only open ${USER.FREE_PLAN_SHARED_PROJ_LIMIT === 1 ? '' : 'up to '}${
        USER.FREE_PLAN_SHARED_PROJ_LIMIT
      } project${
        USER.FREE_PLAN_SHARED_PROJ_LIMIT === 1 ? '' : 's'
      } created by others. Press ${buttonLabel} to change your plan and access more shared projects.`;
      break;
    default:
      // should not happen
      text = `${text}. Press ${buttonLabel} to change your plan.`;
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="contained" color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpgrade} variant="contained" color="secondary">
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
