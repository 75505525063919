import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import PAAppBar from './PAAppBar';
import DrawerWindow from './DrawerWindow';
import Dashboard from './project_overview/Dashboard';
import UserAccount from './UserAccount';
import ActiveWorkflows from './ActiveWorkflows';
import AdminPage from './AdminPage';
import ProjectInfo from './project_overview/ProjectInfo';
import InfoDialog from './InfoDialog';

import { dispatchUpdateViewState } from '../data/actions/userInterface';
import { initMSAzureClientApp } from '../data/actions/msAzureAPI';
import { ContentWrapper } from './styled_components/ContentWrapper';

import * as NAV from '../constants/navigation';
import * as utils from '../utils/generalUtilities.js';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10vh',
  },
  body: {
    display: 'block',
    width: '100hw',
    height: '100vh',
    overflow: 'hidden',
    // backgroundColor: 'red',
  },
  emailStyle: {
    fontSize: '1.2em',
    fontWeight: 600,
    color: theme.palette.secondary.verydark,
    marginBottom: '3px',
  },
}));

export const Body = ({
  user,
  docListenerParent,
  projectOrWorkflow,
  percentageComplete,
  handleProjectInfoChange,
  viewState,
  infoDialog,
  dispatchUpdateViewState,
  initMSAzureClientApp,
}) => {
  const classes = useStyles();

  useEffect(() => {
    initMSAzureClientApp();
  }, []);

  let body;
  if (projectOrWorkflow) {
    switch (viewState.curWindow) {
      case NAV.DASHBOARD:
        body = <Dashboard user={user} handleProjectInfoChange={handleProjectInfoChange} />;
        break;

      case NAV.ADMINPAGE:
        body = (
          <ContentWrapper
            title="Admin Page"
            backToWindow={NAV.DASHBOARD}
            viewState={viewState}
            dispatchUpdateViewState={dispatchUpdateViewState}
            scrollY={true}
            headerMarginBottom={0}
          >
            <AdminPage />
          </ContentWrapper>
        );
        break;

      default:
      case NAV.BOARD:
        body = (
          <DrawerWindow
            user={user}
            handleProjectInfoChange={handleProjectInfoChange}
            project={projectOrWorkflow}
          />
        );
        break;

      case NAV.ACCOUNT:
        body = (
          <ContentWrapper
            title="Your Account:"
            titleChildren={
              <div>
                <Typography className={classes.emailStyle}>{` ${user.email}`}</Typography>
              </div>
            }
            backToWindow={viewState.prevWindow ? viewState.prevWindow : NAV.DASHBOARD}
            viewState={viewState}
            dispatchUpdateViewState={dispatchUpdateViewState}
            scrollY={true}
            headerMarginBottom={0}
          >
            <UserAccount />
          </ContentWrapper>
        );
        break;

      case NAV.SMARTTEMPLATES:
      case NAV.GROUPPAGE:
        // case NAV.TEMPLATE_ACCESS:
        body = (
          <ActiveWorkflows
            user={user}
            handleProjectInfoChange={handleProjectInfoChange}
            project={projectOrWorkflow}
          />
        );
        break;

      case NAV.PROJECT_OVERVIEW:
      case NAV.CREATE_NEW_TEMPLATE:
      case NAV.CREATE_NEW_PROJECT:
        body = (
          <ProjectInfo
            key={`${new Date().getTime()}`}
            label={viewState.curWindow}
            project={projectOrWorkflow}
            validationSchema={utils.projectInfoValidationSchema}
          />
        );
        break;
    }
  } else {
    body = (
      <div className={classes.loadingContainer}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div className={classes.body}>
      <PAAppBar
        project={projectOrWorkflow}
        percentageComplete={percentageComplete ? percentageComplete : 0}
        user={user}
        docListenerParent={docListenerParent}
      />
      {body}
      {infoDialog && <InfoDialog viewState={viewState} />}
    </div>
  );
};
export default connect(
  state => {
    return {
      infoDialog: state.infoDialog,
      viewState: state.viewState,
    };
  },
  {
    dispatchUpdateViewState,
    initMSAzureClientApp,
  },
)(Body);
